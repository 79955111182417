'use strict';

/**
 * @private preventCharacters
 * @param {Event} event - Keydown or paste event
 */
function preventCharacters(event) {
    const { target } = event;
    const bannedCharacters = target.getAttribute('data-prevent');

    if (event.key && bannedCharacters.includes(event.key)) {
        event.preventDefault();
    } else if (event.clipboardData) {
        event.preventDefault();

        const clipboardData = event.clipboardData.getData('text/plain');
        const dataWithoutBannedCharacters = clipboardData
            .split('')
            .filter((character) => !bannedCharacters.includes(character))
            .join('');
        const currValue = target.value;

        if (typeof target.selectionStart === 'number') {
            const start = target.selectionStart;
            const end = target.selectionEnd;
            const newVal = [];

            if (start === 0 && end === 0) {
                newVal.push(dataWithoutBannedCharacters);
                newVal.push(currValue);
            } else {
                newVal.push(currValue.substr(0, start));
                newVal.push(dataWithoutBannedCharacters);
                newVal.push(currValue.substr(end, currValue.length - 1));
            }

            target.value = newVal.join('');
        } else {
            // Some input types don't support `selectionStart`. In this edge case, just append it to the end
            target.value = currValue + dataWithoutBannedCharacters;
        }
    }
}

/**
 * @public preventCharactersOnInput
 * @param {HTMLInputElement} element - The input element
 */
export default function preventCharactersOnInput(element) {
    if (!element.getAttribute('data-prevent')) return;

    element.addEventListener('keydown', preventCharacters);
    element.addEventListener('paste', preventCharacters);
}
