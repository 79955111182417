'use strict';

import { documentReady, getPosition } from 'Util/util';

let lastScrollPos = 0;
let elementsToFadeIn = []; // An array of all "fade in" elements. Will be filled on page load and emptied once the elements are faded in
let currentElements = []; // An array of all elements in view at the time of "checkElements"
let adviceScrollArea;

// Animation vars
const fadeInOffset = 250; // Offset from the bottom of the viewport in pixels, before the element is faded in
const fadeInTime = 700; // The time it takes to fade in an element. should match the scss transition time value
const staggerDelay = 100; // The delay between each element that needs to fade in on page load

const selectors = {
    fadeInElements: '.js-fade-in-element:not(.faded-in)',
    adviceScrollArea: '.js-bscan-step.bscan-advice .js-bscan-step-scroll-area'
};

/**
 * @private updateElementsArrays
 *
 * @description
 * Will update the array of elements to fade in. This is done after some elements have been faded in
 */
function updateElementsArrays() {
    elementsToFadeIn = [...document.querySelectorAll(selectors.fadeInElements)];
    currentElements = [];

    // If there are no more elements, remove the event listener
    if (elementsToFadeIn.length === 0) {
        window.removeEventListener('scroll', checkElements); // eslint-disable-line no-use-before-define
    }
}

/**
 * @private fadeInElements
 *
 * @description
 * Will loop over all elements currently in view, then fade them in
 * In case of multiple elements, the fade-in's are staggered through an incremental transition delay
 */
function fadeInElements() {
    currentElements.forEach((initialElement, index) => {
        initialElement.style.transitionDelay = `${(index * (fadeInTime + staggerDelay)) / 1000}s`;
        initialElement.classList.add('faded-in');
    });

    updateElementsArrays();
}

/**
 * @private checkElements
 *
 * @description Will check to see if there are any elements that need to fade in once they get into view
 */
function checkElements() {
    const currentScrollPos = adviceScrollArea ? adviceScrollArea.scrollTop : window.pageYOffset || document.documentElement.scrollTop;
    const viewHeight = window.innerHeight;
    const isNotScrollingUp = currentScrollPos >= lastScrollPos;

    // If there are elements to fade in, and we're not scrolling up
    if (elementsToFadeIn.length > 0 && isNotScrollingUp) {
        elementsToFadeIn.forEach((element) => {
            // First get the element's position relative to the body (+ a possible offset)
            const elementFadeInPosition = getPosition(element).top + fadeInOffset;
            // Then get the bottom of the current viewport
            const bottomOfViewport = currentScrollPos + viewHeight;

            // If the element is placed higher than the bottom of the current viewport, add it to an array of elements to fade in
            if (elementFadeInPosition < bottomOfViewport) {
                currentElements.push(element);
            }
        });

        fadeInElements();
    }

    lastScrollPos = currentScrollPos <= 0 ? 0 : currentScrollPos;
}

export default (function init() {
    elementsToFadeIn = [...document.querySelectorAll(selectors.fadeInElements)];
    adviceScrollArea = document.querySelector(selectors.adviceScrollArea);

    // Check to see if any elements should be faded in
    documentReady(checkElements); // On document ready so the first one is done ASAP
    window.addEventListener('load', checkElements); // Also on window load, because for some reason the 2nd+ elements can sometimes not fade in without this

    if (adviceScrollArea) {
        adviceScrollArea.addEventListener('scroll', checkElements);
    } else {
        window.addEventListener('scroll', checkElements);
    }

    document.addEventListener('bscan:step', (e) => {
        if (e.detail === 'bs-advice') {
            adviceScrollArea = document.querySelector(selectors.adviceScrollArea);
            if (adviceScrollArea) {
                adviceScrollArea.addEventListener('scroll', checkElements);
            }

            elementsToFadeIn = [...document.querySelectorAll(selectors.fadeInElements)];
            checkElements();
        }
    });

    // Also do it with a timeout for PageDesigner, seeing as the initial execution (nor window.load) does not seem to do much when inside PD
    if (window.frameElement && window.frameElement.closest('.page-designer-ui')) {
        setTimeout(checkElements.bind(true), 1000);
        setTimeout(checkElements.bind(true), 3000);
    }
})();
