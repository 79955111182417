/**
 * Initializes collapsible specifications.
 *
 * @param {HTMLElement} [wrapper] - The wrapper element containing collapsible specs controls.
 * @private
 */
export default function initCollapsibleSpecs(wrapper) {
    const collapseControls = wrapper ? [...wrapper.querySelectorAll('.js-collapsible-specs-control')] : [...document.querySelectorAll('.js-collapsible-specs-control')];

    collapseControls.forEach((control) => {
        control.addEventListener('click', () => {
            // Toggle aria-expanded attribute
            const isExpanded = control.getAttribute('aria-expanded') === 'true';
            control.setAttribute('aria-expanded', (!isExpanded).toString());

            control.classList.toggle('collapsible-specs-control-closed');
            const specs = [...(control.closest('.card')?.querySelectorAll('.js-collapsible-specs') || [])];
            specs.forEach((item) => {
                item.classList.toggle('collapsible-specs-collapsed');
            });
        });
    });
}
