'use strict';

import initCollapsibleSpecs from 'Components/collapsibleSpecs';

/**
 * @class VZDrawer
 */
export default class VZDrawer extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.scrollBarWidth = window.innerWidth - document.body.clientWidth;
        this.pageHeader = document.querySelector('.js-page-header');

        document.addEventListener('hidden.bs.offcanvas', this.removeDrawer.bind(this));
        document.addEventListener('show.bs.offcanvas', this.prepareDocumentBody.bind(this));
        document.addEventListener('hide.bs.offcanvas', this.constructor.preventDrawerClosing.bind(this));
        document.addEventListener('show.bs.modal', this.constructor.preventInfiniteLoop.bind(this));
        initCollapsibleSpecs();
    }

    disconnectedCallback() {
        document.removeEventListener('hidden.bs.offcanvas', this.removeDrawer);
        document.removeEventListener('show.bs.offcanvas', this.prepareDocumentBody);
        document.removeEventListener('hide.bs.offcanvas', this.constructor.preventDrawerClosing);
        document.removeEventListener('show.bs.modal', this.constructor.preventInfiniteLoop);
    }

    /**
     * @function preventInfiniteLoop
     * @description When a drawer is open, if a modal is opened thereafter, an infinite loop can be created. Removing the tabIndex
     * will prevent that. See https://stackoverflow.com/questions/55867609/bootstrap-modal-called-from-jquery-ui-dialog-maximum-call-stack-size-exceeded.
     * @param {Event} event - The show.bs.modal event.
     */
    static preventInfiniteLoop(event) {
        if (document.body.classList.contains('offcanvas-backdrop')) {
            event.target.removeAttribute('tabIndex');
        }
    }

    /**
     * @function preventDrawerClosing
     * @description When a drawer is open, and then a modal is opened, this function prevents the drawer from closing when
     * the modal is interacted with.
     * @param {Event} event - The hide.bs.offcanvas event.
     */
    static preventDrawerClosing(event) {
        if (document.body.classList.contains('modal-open')) {
            event.preventDefault();
        }
    }

    /**
     * @function removeDrawer
     * @description removes the drawer from the DOM
     */
    removeDrawer() {
        document.body.style.paddingRight = 0;
        this.pageHeader.style.paddingRight = 0;

        const parentElement = this.parentElement;
        parentElement.remove();
    }

    /**
     * @function prepareDocumentBody
     * @description Sets some styling on the body to match what the drawer will set from Bootstrap.
     * This is to make sure there is no "jumpy" effect for some properties that are missing initially. (boo Bootstrap)
     * We only need to do this once, as Bootstrap will set the proper styling after the drawer has already been opened once.
     * See BO-1532 for more context.
     */
    prepareDocumentBody() {
        if (!('drawerInitiated' in document.body.dataset)) {
            document.body.dataset.drawerInitiated = '';
            document.body.style.paddingRight = `${this.scrollBarWidth}px`;
            document.body.setAttribute('overflow', 'hidden');
        }

        this.pageHeader.style.paddingRight = `${this.scrollBarWidth}px`;
    }
}

if (!window.customElements.get('vz-drawer')) {
    window.customElements.define('vz-drawer', VZDrawer);
}
