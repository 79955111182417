'use strict';

import { CONST, documentReady } from 'Util/util';

const selectors = {
    searchField: '.js-search-field',
    searchForm: '.js-search-form',
    searchTrigger: '.js-search-trigger'
};

export default documentReady(() => {
    const searchField = document.querySelector(selectors.searchField);
    const searchForm = document.querySelector(selectors.searchForm);
    const trigger = document.querySelector(selectors.searchTrigger);

    if (!searchForm || !searchField) return;

    trigger.addEventListener(CONST.events.click, () => {
        if (searchForm.classList.contains(CONST.classes.active)) {
            searchForm.classList.remove(CONST.classes.active);
        } else {
            searchForm.classList.add(CONST.classes.active);
            searchField.focus();
        }
    });

    searchField.addEventListener(CONST.events.blur, () => {
        searchForm.classList.remove(CONST.classes.active);
        searchField.value = '';
    });
});
