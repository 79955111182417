'use strict';

/**
 * @constant {string} DATE_MASK_ATTRIBUTE - Attribute value for date mask
 */
const DATE_MASK_ATTRIBUTE = 'date';

class DateMask {
    constructor(element) {
        this.field = element;
        this.cursorIndex = 0;
        this.maskText = element.getAttribute('data-mask-text');
        this.pressedCharacters = [];

        this.onInput = this.onInput.bind(this);
        this.onPaste = this.onPaste.bind(this);
        this.replaceRemovedDigitWithLetter = this.replaceRemovedDigitWithLetter.bind(this);
        this.onArrowRight = this.onArrowRight.bind(this);
        this.onArrowLeft = this.onArrowLeft.bind(this);
        this.onCharacterRemoval = this.onCharacterRemoval.bind(this);
        this.checkIfDashIsSelected = this.checkIfDashIsSelected.bind(this);
        this.replaceAsYouType = this.replaceAsYouType.bind(this);

        element.addEventListener('keydown', this.onKeyDown.bind(this));
        element.addEventListener('keyup', this.onInput);
        element.addEventListener('paste', this.onPaste);
    }

    /**
     * @private runRegexOnValue
     * @param {string} value - The value
     * @returns {array} - The final manipulated value as an array with each character
     *
     * @description
     * Mask the input value for date using regular expressions and enforcing dashes at specific locations
     */
    static runRegexOnValue(value) {
        return value
            .toUpperCase() // In case lowercase d/m/j letters are entered
            .replace(/^(\d{1}-)-/, '0$1-') // If we already have the mask and one digit plus a dash has been entered, prepend a 0
            .replace(/-(\d{1}-)-/, '-0$1-') // For the 2nd group, do the same and prepend a 0

            .replace(/-+-/g, '-') // Convert multiple dashes to a single dash
            .replace(/[^\dDMJ-]/g, '') // Remove any character that is not [numeric, letters D/M/J, a dash]
            .replace(/^-(\d{1})/, '$1') // If the string starts with a dash, remove the dash
            .replace(/-/g, '') // After all manipulations and cleaning up, remove all dashes (which could also have been added in the 3rd group) and..
            .split(''); // split up our current string so we can enforce dashes on specific locations only
    }

    /**
     * @private totalLengthUntilLastDigit
     * @param {string} newVal - The value
     * @returns {number} - The total length
     *
     * @description
     * Calculate what the total length of newVal would be once the dashes are added through `splice`, only until the last entered digit
     * We need this to do calculations for cursorOffset
     */
    static totalLengthUntilLastDigit(newVal) {
        const untilLastDigit = newVal.join('').replace(/[\D]*$/, '');
        let totalLength = untilLastDigit.length;
        if (totalLength > 4) {
            totalLength += 1;
        }
        if (totalLength > 2) {
            totalLength += 1;
        }

        return totalLength;
    }

    /**
     * @private replaceRemovedDigitWithLetter
     * @param {string} newVal - The value
     * @returns {array} - The updated newVal
     *
     * @description
     * Will replace the removed digit with a corresponding letter from the maskText
     */
    replaceRemovedDigitWithLetter(newVal) {
        // Calculate the offset which is necessary to determine where the replacing character should be placed
        // The offset is based on the cursorIndex and the amount of dashes that are present
        let cursorOffset = 0;
        const cursorIndex = this.cursorIndex;

        if (cursorIndex === 3 || cursorIndex === 4) {
            cursorOffset -= 1;
        } else if (cursorIndex > 4) {
            cursorOffset -= 2;
        }

        // Decide which character should take it's place and add it to the array
        const removedCharacter = this.maskText.substring(cursorIndex, cursorIndex + 1);

        if (removedCharacter !== '-') {
            newVal.splice(cursorIndex + cursorOffset, 0, removedCharacter);
        }

        return newVal;
    }

    /**
     * @private addDashes
     * @param {string} newVal - The value
     * @returns {array} - The updated newVal
     *
     * @description
     * Will add dashes at specific spots
     */
    static addDashes(newVal) {
        // If we have 4 or more digits, add a dash
        if (newVal.length >= 4) {
            newVal.splice(4, 0, '-');
        }

        // If we have 2 or more digits, add a dash
        if (newVal.length >= 2) {
            newVal.splice(2, 0, '-');
        }

        return newVal;
    }

    /**
     * @private onArrowRight
     * @param {string} newVal - The value
     *
     * @description
     * Will move the cursor to the right based on some checks
     */
    onArrowRight(newVal) {
        if (this.checkIfDashIsSelected(newVal)) this.cursorIndex += 1;
        this.field.setSelectionRange(this.cursorIndex, this.cursorIndex);
    }

    /**
     * @private onArrowLeft
     * @param {string} newVal - The value
     *
     * @description
     * Will move the cursor to the left based on some checks
     */
    onArrowLeft(newVal) {
        if (this.checkIfDashIsSelected(newVal, 'left')) this.cursorIndex -= 1;
        this.field.setSelectionRange(this.cursorIndex, this.cursorIndex);
    }

    /**
     * @private onCharacterRemoval
     * @param {string} newVal - The value
     *
     * @description
     * Will move the cursor to the left based on some checks
     */
    onCharacterRemoval(newVal) {
        // If the character on the left is a dash, move an extra spot to the left
        if (this.checkIfDashIsSelected(newVal, 'left')) this.cursorIndex -= 1;

        // If we end up before the beginning, reset the index
        if (this.cursorIndex < 0) this.cursorIndex = 0;

        this.field.setSelectionRange(this.cursorIndex, this.cursorIndex);
    }

    /**
     * @private checkIfDashIsSelected
     * @param {string} value - The string to check
     * @param {string} direction - Either 'left' or 'right'
     * @param {number} offset - An optional offset
     * @returns {boolean} - True of false
     */
    checkIfDashIsSelected(value, direction, offset = 0) {
        const cursorIndex = this.cursorIndex + offset;

        if (direction === 'left') {
            return value.substring(cursorIndex - 1, cursorIndex) === '-';
        }

        return value.substring(cursorIndex, cursorIndex + 1) === '-';
    }

    /**
     * @private replaceAt
     * @param {string} value - The original value
     * @param {number} index - The position of the old character to replace
     * @param {string} replacement - The replacement character
     * @returns {string} - An updated value
     */
    static replaceAt(value, index, replacement) {
        return value.substring(0, index) + replacement + value.substring(index + 1);
    }

    /**
     * @private replaceAsYouType
     * @param {string} key - The pressed key as a string
     * @returns {string} - A possibly updated value
     * @description
     * Will replace the next character as you type. This is necessary since we have a max-length on the field.
     */
    replaceAsYouType(key) {
        let value = this.field.value;
        const isDigit = !window.isNaN(key);
        const allowedDash = key === '-' && this.checkIfDashIsSelected(value, 'right', 1);

        // If we're not at the end
        // and there's no selection at the moment
        // and the inputted character is either a digit or a dash
        if (this.cursorIndex < value.length && !this.hasSelection && (isDigit || allowedDash)) {
            // If we're before a dash, replace the character after the dash
            if (this.checkIfDashIsSelected(value)) {
                this.cursorIndex++;
            }

            value = this.constructor.replaceAt(value, this.cursorIndex, key);
            this.cursorIndex++;
        }

        return value;
    }

    /**
     * @private onKeyDown
     * @param {KeyboardEvent} evt - On key down event
     */
    onKeyDown(evt) {
        // If the user is holding the key down, and it's not the backspace/delete button, preventDefault
        if (evt.repeat && evt.key !== 'Backspace' && evt.key !== 'Delete') {
            evt.preventDefault();
            return;
        }

        this.hasSelection = evt.target.selectionEnd > evt.target.selectionStart;
        const isDigit = !window.isNaN(evt.key);

        if (isDigit || evt.key === '-') {
            evt.preventDefault();
            this.pressedCharacters.push(evt.key);
            this.onInput(evt);
        }
    }

    /**
     * @private onInput
     * @param {KeyboardEvent} evt - On key up event
     */
    onInput(evt) {
        if (
            evt.type === 'keyup' &&
            (evt.key === 'Escape' ||
                evt.key === 'Enter' ||
                evt.key === 'Tab' ||
                evt.key === 'Control' ||
                evt.key === 'Shift' ||
                evt.key === 'Alt' ||
                evt.key === 'Meta' ||
                evt.key === 'ArrowUp' ||
                evt.key === 'ArrowDown')
        ) {
            return;
        }

        const field = this.field;
        const val = field.value;
        let charRemoved = evt.type === 'keyup' && (evt.key === 'Backspace' || evt.key === 'Delete');
        this.cursorIndex = field.selectionStart;

        // Getting the pressed character from the array is to cater for the edge case where the user held down for example '1' and then pressed '2'.
        // This should force '1' to be shown first and then '2', instead of the other way around if we would just get evt.key.
        // This is a "complex" workaround for a simple problem which would much easier be fixed by using `onKeyDown` instead of `onKeyUp`, but it's
        // too late to refactor half of this file now...
        const firstPressedCharacter = this.pressedCharacters[0];

        // If the entire value/date has been selected
        if (evt.target.selectionStart === 0 && evt.target.selectionEnd === 10) {
            if (firstPressedCharacter) {
                // If a character has been entered, reset the value to the pressed character
                field.value = firstPressedCharacter;
                this.cursorIndex++;
            } else {
                // Stop executing the rest of this method so the user can "select all" and then hit backspace/delete
                return;
            }
        }

        // Init the mask if there is no value yet (seeing as we preventDefault in `onKeyDown`)
        // Also adjust the `empty` class accordingly
        if (val.length === 0 && firstPressedCharacter) {
            field.value = firstPressedCharacter;
            field.classList.remove('empty');
        } else if (val.length === 0) {
            field.classList.add('empty');
        }

        // Replace characters as you type
        let newVal = this.replaceAsYouType(firstPressedCharacter, val);
        field.value = newVal;

        this.pressedCharacters.shift();

        // Mask the input value for date using regular expressions and enforcing dashes at specific locations
        newVal = this.constructor.runRegexOnValue(newVal);

        // Calculate what the total length of newVal would be once the dashes are added through `splice`, only until the last entered digit
        // We need this to do calculations for cursorOffset
        const totalLength = this.constructor.totalLengthUntilLastDigit(newVal);

        // Will replace the removed digit with a corresponding letter from the maskText
        if (charRemoved) {
            newVal = this.replaceRemovedDigitWithLetter(newVal);
        }

        // If we removed any digit "in the middle",
        // Reset `charRemoved` to false, in order to keep the cursor in the current position
        if (charRemoved && this.cursorIndex < totalLength) {
            charRemoved = false;
        }

        // Add dashes at specific spots
        newVal = this.constructor.addDashes(newVal);

        // Set final value with a format of xx-xx-xxxx
        // If the value is incomplete, finish the rest with the mask
        if (totalLength > 0) {
            newVal = newVal.join('').substring(0, 10);
            newVal += this.maskText.substring(newVal.length, 10);
            this.field.value = newVal;
        } else {
            this.field.value = '';
            return;
        }

        // If arrow left was pressed
        if (evt.key === 'ArrowLeft') {
            this.onArrowLeft(newVal);
            return;
        }

        // If arrow right was pressed
        if (evt.key === 'ArrowRight') {
            this.onArrowRight(newVal);
            return;
        }

        // If a character was removed
        if (charRemoved) {
            this.onCharacterRemoval(newVal);
            return;
        }

        // When a character has been entered, increment the cursorIndex
        if (this.checkIfDashIsSelected(newVal)) {
            this.cursorIndex += 1;
        }

        // Set the new selection based on calculations done in above methods
        this.field.setSelectionRange(this.cursorIndex, this.cursorIndex);
    }

    /**
     * @private onPaste
     * @param {KeyboardEvent} evt - On key up event
     */
    onPaste(evt) {
        this.onInput(evt);

        setTimeout(() => {
            const val = this.field.value;
            this.field.setSelectionRange(val.length, val.length);
        }, 100); // small delay is necessary because the dashes are added after paste
    }
}

export { DateMask, DATE_MASK_ATTRIBUTE };
