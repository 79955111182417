'use strict';

import Mask from 'Components/form/mask';

/**
 * @public regex
 * @param {HTMLInputElement} element - The element to put the mask on
 */
export default function mask(element) {
    Object.defineProperty(element, 'mask', {
        enumerable: false,
        configurable: false,
        value: new Mask(element)
    });
}
