'use-strict';

import ModalHelper from './modalHelper';

/**
 * @class VZModal
 * @description Creates a modal based on defaultModalHTML or the HTML within the custom element.
 * @example
 * <vz-modal
        class="modal fade specific-modal-name" // Class can be omitted if not needed for styling.
        id="specific-modal-name"
        data-backdrop="static"
        data-keyboard="false"
        data-custom-modal="" // Set to true if custom HTML is added within the custom element. All attributes below can be omitted if set to true.
        data-title-text=""
        data-body-text=""
        data-primary-btn-text=""
        data-primary-btn-url=""
        data-primary-btn-target="" // Target attributes are only needed if the button has a target, otherwise can be omitted.
        data-secondary-btn-text=""
        data-secondary-btn-url=""
        data-secondary-btn-target=""
        data-tertiary-btn-text="" // tertiary btn options are only necessary if the modal has a third button, otherwise can be omitted.
        data-tertiary-btn-url=""
        data-tertiary-btn-target=""
        data-close-btn-url=""
        data-modal-footer-classes="" // Only needed if footer has additional classes, other wise can be omitted.
        data-icon-name // Name of the svg icon. e.g. 'engineer'
        data-is-secondary-btn-dismiss // Should be set to true or false. When true, the secondary button will act as a dismiss button and will close the modal
        data-body-title // An optional red title displayed inside the modal body
    >
        <div>Optional custom HTML if not using defaultModalHTML</div>
    </vz-modal>
 * @usage
 * const modal = document.getElementById('specific-modal-name');
 * modal.render(); // An optional object can be passed to render() to overwrite the defaults.
 */
export default class VZModal extends HTMLElement {
    constructor() {
        super();
        this.setAttribute('tabindex', '-1');
        this.setAttribute('aria-hidden', 'true');
        this.hasCustomHtml = this.dataset.customModal === 'true';
        this.modalHelper = new ModalHelper();
    }

    /**
     * @function createTemplate
     * @description Injects the default modal HTML into the modal container.
     * @param {string} templateHtml - String representing default modal HTML.
     */
    createTemplate(templateHtml) {
        const template = document.createElement('template');
        template.innerHTML = templateHtml;
        this.appendChild(template.content.cloneNode(true));
    }

    connectedCallback() {
        document.addEventListener('minicart:open', this.modalHelper.renderAbandonConfigurationModal);
    }

    disconnectedCallback() {
        document.removeEventListener('minicart:open', this.modalHelper.renderAbandonConfigurationModal);
    }

    /**
     * @function render
     * @description Renders the contents of the addToBasketModal and injects text based on data attributes.
     * @param {Object} options - An optional parameter with configuration options for the modal. To be used to overwrite the default options.
     */
    render(options = {}) {
        // If the default modal does not fit the requirement, custom HTML can be added within the custom element.
        if (this.hasCustomHtml) {
            this.showModal();
            return;
        }

        this.configurationOptions = { ...this.dataset, ...ModalHelper.defaultOptions, ...options };
        const defaultModalHTML = ModalHelper.getDefaultModalHtml(this.configurationOptions);

        // Remove existing modal so the template can be recreated with new content.
        this.querySelector('.js-modal-dialog')?.remove();

        this.createTemplate(defaultModalHTML); // Inject the template, add it to the DOM.

        this.showModal(); // Display the modal.
    }

    /**
     * @function showModal
     * @description Checks if modal already exists and either creates a new one to show, or shows the existing one.
     */
    showModal() {
        const { backdrop, keyboard } = { ...this.configurationOptions, ...this.dataset }; // If passed in the dataset, the default values for keyboard and backdrop will be overwritten.
        const keyboardSetting = typeof keyboard === 'string' ? keyboard === 'true' : keyboard;

        const newModal = new window.bs.Modal(this, { backdrop, keyboard: keyboardSetting }); // Options come from data attributes.
        if (newModal) newModal.show();
    }

    /**
     * @function hideModal
     * @description Hides the modal
     */
    hideModal() {
        window.bs.Modal.getInstance(this)?.hide();
    }
}

if (!window.customElements.get('vz-modal')) {
    window.customElements.define('vz-modal', VZModal);
}
