'use strict';

/**
 * @constant {string} PASSWORD_VIEW_TOGGLE - Selector for toggling the view state of a password field.
 */
const PASSWORD_VIEW_TOGGLE = '.js-password-toggle';

/**
 * @public showPassword
 * @param {HTMLInputElement} element - The element used to set the password toggle.
 */
export default function showPassword(element) {
    const feedbackElement = element.parentElement.querySelector(PASSWORD_VIEW_TOGGLE);
    if (!feedbackElement) return;

    feedbackElement.addEventListener('click', () => {
        element.showPassword = element.type === 'password';
    });
    Object.defineProperty(element, 'showPassword', {
        enumerable: false,
        configurable: false,
        set: (value) => {
            if (value) {
                element.type = 'text';
            } else {
                element.type = 'password';
            }
        }
    });
}
