'use strict';

import { CONST, addEvent, exists, isAemMobileView, assignStyling, documentReady } from 'Util/util';
import * as debounce from 'lodash/debounce';

const selectors = {
    listColumn: '.js-col-link-list',
    listLink: 'h6 a',
    listGroup: '.list-group'
};

const siteFooter = document.querySelector('.js-site-footer');

/**
 * @private toggleMobileMenu
 * @param {Event} event - The click/tap event
 */
function toggleMobileMenu(event) {
    const element = event.target;
    const listElements = document.querySelectorAll(`${selectors.listColumn} ${selectors.listLink}, ${selectors.listColumn} ${selectors.listGroup}`);
    const currentColumn = element.closest(selectors.listColumn);
    const currentElements = currentColumn.querySelectorAll(`${selectors.listLink}, ${selectors.listGroup}`);

    if (!exists(listElements) || !exists(currentElements)) {
        console.error('fn toggleMobileMenu: required elements are missing');
        return;
    }

    if (isAemMobileView()) {
        event.preventDefault();
    }

    [...listElements].forEach((listElement) => listElement.classList.remove(CONST.classes.active));
    [...currentElements].forEach((currentElement) => currentElement.classList.add(CONST.classes.active));
}

/**
 * @private checkFooterOverlaping
 */
function checkFooterOverlaping() {
    const notFoundAbsoluteContainer = document.querySelector('#js-404-page-absolute-container');
    if (exists(notFoundAbsoluteContainer)) {
        const absolutelyPositionedElementRect = notFoundAbsoluteContainer.getBoundingClientRect();
        const siteFooterRect = siteFooter.getBoundingClientRect();

        if (absolutelyPositionedElementRect.bottom >= siteFooterRect.top) {
            siteFooter.classList.remove('mt-5');
            assignStyling(siteFooter, {
                'margin-top': `${absolutelyPositionedElementRect.bottom - siteFooterRect.top + 10}px`
            });
        }
    }
}

addEvent(document, CONST.events.click, `${selectors.listColumn} ${selectors.listLink}`, toggleMobileMenu);
documentReady(checkFooterOverlaping);
window.addEventListener(
    'resize',
    debounce(() => {
        siteFooter.classList.add('mt-5');
        siteFooter.style.removeProperty('margin-top');
        checkFooterOverlaping();
    }),
    150
);
