'use strict';

const events = ['keydown', 'paste', 'focus', 'mousedown'];

/**
 * Prevent entry in the field
 * @param {Event} evt - An event
 */
function preventEntry(evt) {
    // ignore tab
    if (evt.keyCode !== 9) evt.preventDefault();
}

/**
 * Enable the read-only feature
 * @param {HTMLElement} element - The element to make read-only
 * @returns {bool} enabled
 */
function enable(element) {
    events.forEach((event) => {
        element.addEventListener(event, preventEntry);
    });
    return true;
}

/**
 * Disable the read-only feature
 * @param {HTMLElement} element - The element to make read-only
 * @returns {bool} enabled
 */
function disable(element) {
    events.forEach((event) => {
        element.removeEventListener(event, preventEntry);
    });
    return false;
}

/**
 * @public idType
 * @param {HTMLSelectElement} element - Identification type selector
 */
export default function idType(element) {
    if (!element.hasAttribute('read-only')) return;
    Object.defineProperty(element, 'read-only', {
        enumerable: false,
        configurable: false,
        set: (bool) => {
            if (bool) {
                this.value = enable(element);
                return;
            }
            this.value = disable(element);
        }
    });
    element['read-only'] = element.getAttribute('read-only');
}
