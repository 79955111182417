'use strict';

import { documentReady } from 'app_vodafone_ziggo/util/util';
let counter = 0;

/**
 * Checks if Google Analytics (GA) is loaded and fires custom event.
 * If GA is not loaded,it will keep trying to check 10 times.
 *
 * @returns {void}
 */
export function checkGA() {
    const gaCheckInterval = setInterval(() => {
        counter++;
        if (typeof window.ga === 'function') {
            document.dispatchEvent(new CustomEvent('ga:loaded'));
            clearInterval(gaCheckInterval);
            return;
        }
        if (counter > 9) clearInterval(gaCheckInterval);
    }, 500);
}

/**
 * @private onDocumentReady
 */
function onDocumentReady() {
    document.body.classList.add('ready');
    if (document.querySelector('.js-account-navigation')) {
        document.body.classList.add('logged-in');
    }
    checkGA();
}

documentReady(onDocumentReady);
