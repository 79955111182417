'use strict';

// Can't import cartridges/app_vodafone_ziggo/cartridge/scripts/forms/validator.js
const ID_TYPES_CODES = {
    idCard: '1',
    passport: '2',
    driversLicense: '3',
    permit1: '4',
    permit2: '5',
    permit3: '6',
    permit4: '7',
    permit5: '8',
    permitEU: '9',
    other: '10'
};

const ID_VALIDATION_RULES = {};
ID_VALIDATION_RULES[ID_TYPES_CODES.passport] = /^[A-NP-Z]{2}[\dA-NP-Z]{6}\d{1}$/gi;
ID_VALIDATION_RULES[ID_TYPES_CODES.idCard] = ID_VALIDATION_RULES[ID_TYPES_CODES.passport];
ID_VALIDATION_RULES[ID_TYPES_CODES.driversLicense] = /^\d{10}$/gi;
ID_VALIDATION_RULES[ID_TYPES_CODES.permit1] = /^(?!(X00|V))[\dA-Z]{8,9}$/gi;
ID_VALIDATION_RULES[ID_TYPES_CODES.permit2] = ID_VALIDATION_RULES[ID_TYPES_CODES.permit1];
ID_VALIDATION_RULES[ID_TYPES_CODES.permit2] = ID_VALIDATION_RULES[ID_TYPES_CODES.permit1];
ID_VALIDATION_RULES[ID_TYPES_CODES.permit3] = ID_VALIDATION_RULES[ID_TYPES_CODES.permit1];
ID_VALIDATION_RULES[ID_TYPES_CODES.permit4] = ID_VALIDATION_RULES[ID_TYPES_CODES.permit1];
ID_VALIDATION_RULES[ID_TYPES_CODES.permit5] = ID_VALIDATION_RULES[ID_TYPES_CODES.permit1];
ID_VALIDATION_RULES[ID_TYPES_CODES.permitEU] = ID_VALIDATION_RULES[ID_TYPES_CODES.permit1];

// this regexp is always invalid
ID_VALIDATION_RULES[ID_TYPES_CODES.other] = /(?=a)b/;

/**
 * @constant {string} ID_TYPE - Selector for identification type
 */
const ID_TYPE = 'dwfrm_checkoutDetails_identification_idtype';

/**
 * @private setValidationPattern
 * @param {Event} evt - Change or load event
 */
function setValidationPattern(evt) {
    const element = evt.target.id === ID_TYPE ? evt.target : document.getElementById(ID_TYPE);
    if (element.value) {
        element.closest('form').dispatchEvent(
            new CustomEvent('identificationTypeChanged', {
                detail: {
                    type: element.value,
                    regex: ID_VALIDATION_RULES[element.value]
                }
            })
        );
    }
}

/**
 * @public idType
 * @param {HTMLSelectElement} element - Identification type selector
 */
export default function idType(element) {
    if (element.id !== ID_TYPE) return;

    element.addEventListener('change', setValidationPattern);
    window.addEventListener('load', setValidationPattern);
}
