'use strict';

import { POSTCODE_MASK_ATTRIBUTE, PostCodeMask } from './masks/postcode';
import { IBAN_MASK_ATTRIBUTE, IBANMask } from './masks/iban';
import { DATE_MASK_ATTRIBUTE, DateMask } from './masks/date';
import { CAPS_MASK_ATTRIBUTE, CapsMask } from './masks/caps';

/**
 * @class Mask
 * @param {HTMLInputElement} element - The input field to put a mask on
 */
class Mask {
    constructor(element) {
        this.element = element;
        this.maskType = this.element.getAttribute('data-mask');

        // PostCode mask
        if (this.maskType === POSTCODE_MASK_ATTRIBUTE) {
            this.mask = new PostCodeMask(this.element);
        }
        if (this.maskType === IBAN_MASK_ATTRIBUTE) {
            this.mask = new IBANMask(this.element);
        }
        if (this.maskType === DATE_MASK_ATTRIBUTE) {
            this.mask = new DateMask(this.element);
        }
        if (this.maskType === CAPS_MASK_ATTRIBUTE) {
            this.mask = new CapsMask(this.element);
        }
    }
}

export default Mask;
