'use strict';

import 'Root/VZLeadForm';
import 'Root/VZLeadFormTrigger';

class ChatPrompt {
    constructor() {
        this.chatOfflineButton = document.querySelector('.dimelo_chat_show_when_offline');
        this.activeConfiguratorGroup = document.getElementById('activeConfiguratorGroup');
        this.committedConfiguratorGroups = document.getElementById('committedConfiguratorGroups');
        this.customerEmail = document.getElementById('customerEmail');
        this.lastOrderNr = document.getElementById('lastOrderNr');

        this.prompt = false;
        window.dataLayer = window.dataLayer || [];

        this.assignListeners = this.assignListeners.call(this);
        this.openChatPrompt = this.openChatPrompt.bind(this);
        this.closeChatPrompt = this.closeChatPrompt.bind(this);
        this.openChatWhenLoaded = this.openChatWhenLoaded.bind(this);

        this.addConfiguratorGroupsToDatalayer = this.addConfiguratorGroupsToDatalayer.bind(this);

        if (this.chatOfflineButton && this.chatOfflineButton.length) {
            this.chatOfflineButton.setAttribute('tabindex', 0); // Do this so the element can get a `:focus` state
        }
    }

    assignListeners() {
        window.addEventListener('load', () => {
            this.addConfiguratorGroupsToDatalayer.call(this);

            if (this.customerEmail) {
                window.dataLayer.push({
                    customerEmail: this.customerEmail.value
                });
            }
            if (this.lastOrderNr) {
                window.dataLayer.push({
                    lastOrderNr: this.lastOrderNr.value
                });
            }
            this.openByDefault = document.querySelector('.js-dimelo-chat-state')?.value === 'show';
            if (this.openByDefault) {
                if (this.chatOfflineButton) {
                    this.chatOfflineButton.querySelector('.js-btn-leadmodal')?.click();
                } else {
                    this.interval = setInterval(this.openChatWhenLoaded, 1000);
                }
            }
        });

        window.addEventListener('dimelo:chat:load', () => {
            this.openByDefault = document.querySelector('.js-dimelo-chat-state')?.value === 'show';
            this.chatOfflineButton.addEventListener('click', this.openChatPrompt);
            if (this.openByDefault) {
                this.openChatPrompt();
            }
        });
    }

    openChatPrompt() {
        if (!this.prompt) {
            const target = this.chatOfflineButton.getAttribute('data-target');
            this.prompt = document.querySelector(target);
        }

        if (this.prompt) {
            this.prompt.classList.add('active');
            this.closeButton = this.prompt.querySelector('.js-btn-prompt-cancel');
            this.closeButton.addEventListener('click', this.closeChatPrompt);
        } else {
            this.chatOfflineButton.removeEventListener('click', this.openChatPrompt);
        }
    }

    closeChatPrompt(event) {
        event.preventDefault();
        this.prompt.classList.remove('active');
        this.closeButton.removeEventListener('click', this.closeChatPrompt);
    }

    // Retrieve the configuration from the dataLayer like this:
    // google_tag_manager[containerId].dataLayer.get(groupName);
    addConfiguratorGroupsToDatalayer() {
        [this.committedConfiguratorGroups, this.activeConfiguratorGroup].forEach((group) => {
            if (!group) return;

            this.constructor.pushToDataLayer(group);

            group.addEventListener('locationChange', (customEvent) => {
                this.constructor.updateGroupLocation(group, customEvent);
                this.constructor.pushToDataLayer(group);
            });

            group.addEventListener('change', () => {
                this.constructor.pushToDataLayer(group);
            });
        });
    }

    static pushToDataLayer(group) {
        if (!group) return;

        const groupObj = {};
        groupObj[group.id] = JSON.parse(group.value);
        window.dataLayer.push(groupObj);
    }

    static updateGroupLocation(group, customEvent) {
        const data = JSON.parse(group.value);
        const address = customEvent.detail;

        data.location = {
            street: address.street,
            houseNumber: address.houseNumber,
            appendix: address.extension,
            postCode: address.postalCode,
            city: address.city,
            room: address.room,
            paid: address.paid
        };

        group.value = JSON.stringify(data);
    }

    /**
     * Opens the chat when the chat button is loaded and available.
     * Clears the interval once the chat button is clicked.
     * @returns {void}
     */
    openChatWhenLoaded() {
        const chatBtn = document.querySelector('.dimelo_chat_show_when_online button');
        if (chatBtn) {
            chatBtn.click();
            clearInterval(this.interval);
        }
    }
}

new ChatPrompt(); // eslint-disable-line no-new
