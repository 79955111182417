'use strict';

import { getPosition, scrollTo } from 'app_vodafone_ziggo/util/util';

/**
 * @private scrollToHash
 */
function scrollToHash() {
    const hash = window.location.hash;

    if (hash) {
        const element = document.querySelector(hash);

        if (!element) return;
        if (element.classList.contains('faq-item')) return; // FAQ has it's own scrolling

        const elementPosition = getPosition(element);

        // Scroll after a really short timeout for some edgecases where a browser's autoscroll is
        // firing after our custom `scrollTo` functionality. This might have something to do with the
        // callstack, so make sure our `scrollTo` is fired last.
        setTimeout(() => {
            scrollTo(elementPosition);
        }, 1);
    }
}

window.addEventListener('load', scrollToHash);
window.addEventListener('hashchange', scrollToHash, false);
