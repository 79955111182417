'use strict';

import { getCurrentBreakpoint } from 'Util/util';

/**
 * @private detectBreakpointChange
 */
function detectBreakpointChange() {
    const currentBreakpoint = getCurrentBreakpoint();

    if (JSON.stringify(window.currentBreakpoint) !== JSON.stringify(currentBreakpoint)) {
        const customEvent = new CustomEvent('breakpointChange', {
            detail: {
                breakpoint: currentBreakpoint
            }
        });
        document.dispatchEvent(customEvent);
    }

    window.currentBreakpoint = currentBreakpoint;
}

window.addEventListener('resize', detectBreakpointChange);
