'use strict';

import Drawer from 'bootstrap/js/src/offcanvas';
import VZSpinner from 'Components/VZSpinner';
import { appendCsrfToUrl, getParamFromURL, removeParamFromURL } from 'Util/urlUtils';
import { showErrorModal } from 'Root/modal';
import { appendParamToURL } from 'Root/util/urlUtils';
import { loadCSS } from 'Util/util';

const spinner = document.querySelector('vz-spinner') || new VZSpinner();

/**
 * @class VZOpenDrawer
 * @description Specifically used for the more information drawer
 * @example
 * <vz-open-drawer data-drawer-url="${URLUtils.url('Drawer-Show', 'titleKey', titleResourceKey, 'descriptionKey', descriptionResourceKey, 'bundle', resourceBundle)}">
 *      <AnyHTMLElement/>
 * </vz-open-drawer>
 */
export default class VZOpenDrawer extends HTMLElement {
    constructor() {
        super();

        this.endpoint = this.dataset.drawerUrl;
        this.onClick = this.onClick.bind(this);
        this.handleSuccessResponse = this.handleSuccessResponse.bind(this);
    }

    connectedCallback() {
        this.isMinicartButton = this.classList.contains('js-minicart-button');
        this.isEditMode = document.querySelector('.js-edit-mode');
        this.addEventListener('click', this.onClick);
        this.showMinicartDrawerOnLoad();
    }

    disconnectedCallback() {
        this.removeEventListener('click', this.onClick);
    }

    /**
     * @function showDrawer
     * @description creates a Boostrap offcanvas drawer and shows it
     * @param {HTMLElement} drawerElement the element from which to create a Boostrap Offcanvas element (A.K.A Drawer)
     */
    static showDrawer(drawerElement) {
        const bsDrawer = new Drawer(drawerElement);
        bsDrawer.show();
    }

    /**
     * @function showMinicartDrawerOnLoad
     * @description Reopens the minicart after a product is deleted from it and the page reloads.
     * @returns {void} Early exit if the button is not the minicart button.
     */
    showMinicartDrawerOnLoad() {
        if (!this.isMinicartButton) return;
        const showMinicart = getParamFromURL(window.location.href, 'showMinicart') === 'true';

        if (showMinicart) {
            this.dispatchEvent(new CustomEvent('click'));

            // To avoid opening the minicart again and again on page refresh, the param needs to removed
            const urlWithoutParam = removeParamFromURL(window.location.href, 'showMinicart');
            window.history.pushState(null, '', urlWithoutParam); // Update URL without refreshing the page
        }
    }

    /**
     * @function addDrawerToDOM
     * @description adds the drawer returned from the endpoint to the DOM
     * @param {JSON} data returned from calling Drawer-Show
     */
    static addDrawerToDOM(data) {
        const body = document.body;
        body.insertAdjacentHTML('beforeend', data.html);
    }

    /**
     * @function handleSuccessResponse
     * @description handles a successful call to Drawer-Show
     * @param {JSON} data returned from calling Drawer-Show
     */
    handleSuccessResponse(data) {
        this.constructor.addDrawerToDOM(data);
        loadCSS(this.getAttribute('loadcss'));
        const drawer = document.getElementById('drawer');
        const drawerElement = drawer.closest('.js-drawer-container');

        if (!drawerElement) return;
        this.constructor.showDrawer(drawerElement);
        if (this.isMinicartButton && !this.isEditMode) {
            document.dispatchEvent(new CustomEvent('minicart:open')); // This will open the abandonConfigurationModel triggered inside VZModal.js
        }
        if (this.isMinicartButton && !this.isEditMode) {
            document.dispatchEvent(new CustomEvent('minicart:open')); // This will open the abandonConfigurationModel triggered inside VZModal.js
        }
    }

    /**
     * @function onClick
     * @description Handles the click event on an open drawer button
     * @param {Event} event - The click event
     */
    async onClick(event) {
        event.preventDefault(); // This is needed so that checkboxes on the configurator are not checked when more information is clicked
        const endpointWithCsrf = appendCsrfToUrl(this.endpoint);
        const isConfiguratorPage = this.isMinicartButton ? this?.dataset.isConfiguratorPage : false;

        // Get and Add the pliId to the querystring to make it available in the minicart drawer only in edit mode.
        const productPliID = this.isMinicartButton && this.isEditMode ? getParamFromURL(window.location.href, 'pliId') : false;
        const editModeEndpoint = productPliID ? appendParamToURL(endpointWithCsrf, 'productPliID', productPliID) : false;
        const finalEndpoint = this.isMinicartButton ? appendParamToURL(editModeEndpoint || endpointWithCsrf, 'isConfiguratorPage', isConfiguratorPage) : endpointWithCsrf;

        spinner.start();
        try {
            const response = await fetch(finalEndpoint);

            if (!response.ok) {
                throw new Error(`There was an error fetching the endpoint - ${this.endpoint} - status: ${response.status}`);
            }

            const data = await response.json();
            this.handleSuccessResponse(data);
        } catch (error) {
            console.warn(error);
            const { title, body, cta } = JSON.parse(this.dataset?.generalError);
            showErrorModal(title, body, cta);
        } finally {
            spinner.stop();
        }
    }
}

if (!window.customElements.get('vz-open-drawer')) {
    window.customElements.define('vz-open-drawer', VZOpenDrawer);
}
