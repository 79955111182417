'use strict';

import decorators from 'Decorators/index';
import { validate } from 'app_vodafone_ziggo/util/validation';

/**
 * @class Select
 * @param {HTMLSelectElement} element - The element to create an instance on
 */
export default class VZSelect extends HTMLSelectElement {
    constructor() {
        super();

        decorators.valid(this, this);
        decorators.readonly(this, this);

        if (this.classList.contains('js-custom-select-validation')) {
            this.addEventListener('change', validate);
        }

        this.addEventListener('blur', validate);
    }

    connectedCallback() {
        this.selectCorrectValue();
    }

    /**
     * @public selectCorrectValue
     * @description
     * Safari decided to preselect the second item in the row for no reason.
     */
    selectCorrectValue() {
        const selectedValue = [...this.options].find((opt) => {
            return opt.getAttribute('selected') !== null;
        });

        this.selectedIndex = [...this.options].indexOf(selectedValue);

        if (
            (this.getAttribute('name').includes('identification_nationality') ||
                this.getAttribute('name').includes('identification_idtype') ||
                this.getAttribute('name').includes('contact_phonePrefix')) &&
            this.selectedIndex !== 0
        ) {
            this.dispatchEvent(new CustomEvent('blur'));
        }
    }
}

if (!window.customElements.get('vz-select')) {
    window.customElements.define('vz-select', VZSelect, { extends: 'select' });
}
