'use strict';

import * as debounce from 'lodash/debounce';
import { CONST, documentReady, isAemMobileView } from 'Util/util';
import 'Components/modals/VZModal';

const classes = {
    navigationActive: 'navigation-active',
    open: 'open',
    scrollLock: 'scroll-lock',
    sticky: 'sticky'
};

const selectors = {
    body: 'body',
    flyout: '.flyout',
    header: 'header',
    navigation: '.js-navigation',
    navigationColumn: '.js-navigation-column',
    navigationItems: '.js-navigation-item',
    navigationTitles: '.js-navigation-column-title',
    navigationToggler: '.js-navigation-toggler',
    navigationWrapper: '.js-navigation-wrapper',
    notification: '.js-notification',
    notificationContent: '.js-notification .alert',
    subNavigation: '.js-sub-navigation',
    subNavigationWrapper: '.js-sub-navigation-wrapper',
    storefrontPage: '.pd-page',
    subNavigationBtnSmallBusiness: '.js-btn-small-business',
    subNavigationBtnLargeBusiness: '.js-btn-large-business'
};

const body = document.querySelector(selectors.body);
const notification = document.querySelector(selectors.notification);
let header;
let navigation;
let navigationToggler;
let navigationWrapperMobile;

/**
 * @private toggleStickyClass
 *
 * @description
 * Toggle the sticky class on the header on page scroll
 */
function toggleStickyClass() {
    if (window.pageYOffset > 0) {
        body.classList.add(classes.sticky);
    } else {
        body.classList.remove(classes.sticky);
    }
}

/**
 * @private updateNotificationHeight
 *
 * @description
 * If there is a notification element:
 * - Adjust the padding on the body
 * - Set a height on the notification for css-transition purposes
 */
function updateNotificationHeight() {
    notification.setAttribute('style', 'height: auto !important');
    body.style.paddingTop = 'auto';

    const notificationHeight = notification.offsetHeight;
    const headerHeight = header.offsetHeight;

    notification.style.height = `${notificationHeight}px`;
    body.style.paddingTop = `${headerHeight}px`;
}

/**
 * @function getFlyoutMinHeight
 * @description
 * Iterates over navigation items to get the largest flyout.
 * @return {string} flyout minimum height
 */
function getFlyoutMinHeight() {
    if (!isAemMobileView) return 'auto';

    return `${[...navigation.querySelectorAll(selectors.flyout)].reduce((result, item) => {
        return result > item.clientHeight ? result : item.clientHeight;
    }, 0)}px`;
}

/**
 * @function setFlyoutHeight
 * @default
 * Sets the flyout minimum height on desktop to the largest flyout.
 * On mobile the minimum height is reset to auto.
 */
function setFlyoutHeight() {
    if (isAemMobileView) return;

    const flyouts = [...navigation.querySelectorAll(selectors.flyout)];
    const flyoutMinHeight = getFlyoutMinHeight();
    flyouts.forEach((flyout) => {
        flyout.style.minHeight = flyoutMinHeight;
    });
}

/**
 * @private handleDesktopHoverClasses
 *
 * @description
 * Will toggle an active class on the navigation when hovering over navigation items for desktop
 */
function handleDesktopHoverClasses() {
    if (!isAemMobileView) return;

    navigationWrapperMobile?.addEventListener(CONST.events.mouseover, () => {
        body.classList.add(classes.scrollLock);
        navigation.classList.add(classes.navigationActive);
        navigationToggler.classList.add(classes.navigationActive);
    });
    navigationWrapperMobile?.addEventListener(CONST.events.mouseleave, () => {
        body.classList.remove(classes.scrollLock);
        navigation.classList.remove(classes.navigationActive);
        navigationToggler.classList.remove(classes.navigationActive);
    });
}

/**
 * @private bindItemToggling
 * @param {HTMLElement} item - The navigation item that can be toggled
 *
 * @description
 * Will create toggling functionality on navigation items
 */
function bindItemToggling(item) {
    item.addEventListener(CONST.events.click, (evt) => {
        const targetElement = evt.target.closest(selectors.navigationColumn);
        if (targetElement.classList.contains(classes.open)) {
            targetElement.classList.remove(classes.open);
        } else {
            targetElement.classList.add(classes.open);
        }
    });
}

/**
 * @private updateMobileMenuPosition
 *
 */
function updateMobileMenuPosition() {
    if (isAemMobileView && navigationWrapperMobile) navigationWrapperMobile.style.top = `${header.offsetHeight}px`;
}

/**
 * @private toggleMobileMenu
 *
 * @description
 * Calculate top offset for navigation & handle scrollLock/active classes
 */
function toggleMobileMenu() {
    updateMobileMenuPosition();

    if (navigation.classList.contains(classes.navigationActive)) {
        body.classList.remove(classes.scrollLock);
        navigation.classList.remove(classes.navigationActive);
        navigationToggler.classList.remove(classes.navigationActive);
    } else {
        body.classList.add(classes.scrollLock);
        navigation.classList.add(classes.navigationActive);
        navigationToggler.classList.add(classes.navigationActive);
    }
}

export default documentReady(() => {
    header = document.querySelector(selectors.header);
    navigation = document.querySelector(selectors.navigation);
    const subNavigation = document.querySelector(selectors.subNavigation);
    const subNavigationBtnSmallBusiness = document.querySelector(selectors.subNavigationBtnSmallBusiness);
    const subNavigationBtnLargeBusiness = document.querySelector(selectors.subNavigationBtnLargeBusiness);

    if (!navigation) return;

    navigationToggler = navigation.querySelector(selectors.navigationToggler);
    navigationWrapperMobile = document.querySelector(selectors.navigationWrapper);

    // If there is a notification, adjust the padding on the body and set a height on the notification
    if (document.querySelector(selectors.notificationContent)) {
        updateNotificationHeight();

        window.addEventListener(
            'resize',
            debounce(() => {
                updateNotificationHeight();
                updateMobileMenuPosition();
                setFlyoutHeight();
            }, 100)
        );
    }

    // Handle scrolling & sticky header
    toggleStickyClass();
    document.addEventListener('scroll', toggleStickyClass);

    // Handle classes for desktop
    handleDesktopHoverClasses();
    setFlyoutHeight();

    // Handle classes & offset for mobile navigation
    if (!navigationToggler) return;
    navigationToggler.addEventListener(CONST.events.click, toggleMobileMenu);

    // For mobile, add a click event to toggle collapse
    [...navigation.querySelectorAll(selectors.navigationTitles)].forEach(bindItemToggling);

    [subNavigationBtnSmallBusiness, subNavigationBtnLargeBusiness]
        .filter((item) => item)
        .forEach((subNavButton) => {
            subNavButton.addEventListener(CONST.events.click, (evt) => {
                const isEmptyBasket = subNavigation.dataset.basketIsEmpty;
                if (isEmptyBasket === 'false') {
                    evt.preventDefault();
                    const notEmptyBasketModal = document.getElementById('modal-basket-not-empty');
                    const primaryBtnText = subNavButton.dataset.modalBtnText;
                    notEmptyBasketModal.render({ primaryBtnText, primaryBtnUrl: evt.target.href });
                }
            });
        });
});
