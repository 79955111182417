'use strict';

import { CapsMask } from './caps';

/**
 * @constant {string} POSTCODE_MASK_ATTRIBUTE - Attribute value for postcode mask
 */
const IBAN_MASK_ATTRIBUTE = 'iban';

/**
 * @constant {integer} BACKSPACE_KEYCODE
 */
const BACKSPACE_KEYCODE = 8;

/**
 * @constant {integer} DELETE_KEYCODE
 */
const DELETE_KEYCODE = 46;

/**
 * @constant {Array} UNDO_KEYS
 */
const UNDO_KEYS = [BACKSPACE_KEYCODE, DELETE_KEYCODE, 'backspace', 'delete'];

class IBANMask extends CapsMask {
    constructor(element) {
        super(element);
        this.element = element;
        this.onKeyUp = this.onKeyUp.bind(this);

        element.addEventListener('keyup', this.onKeyUp);
    }

    /**
     * @private onKeyUp
     * @param {KeyboardEvent} evt - On key up event
     */
    onKeyUp(evt) {
        const key = evt.key && typeof evt.key !== 'number' ? evt.key.toLocaleLowerCase() : evt.key || null;
        if (key && UNDO_KEYS.some((k) => k === key)) {
            if (this.element.value.length % 4 === 1) {
                this.element.value = this.element.value.substring(0, this.element.value.length);
            }
            return;
        }

        // Mask the input value for iban using a regular expression.
        this.element.value = this.element.value
            .replace(/\s/g, '')
            .replace(/^([a-zA-Z]{2}\d{2})(\w{0,4})(\d{0,4})(\d{0,4})(\d{0,2})/, '$1 $2 $3 $4 $5')
            .replace(/\s\s+/g, ' ');
    }
}

export { IBANMask, IBAN_MASK_ATTRIBUTE };
