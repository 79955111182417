'use strict';

import { getPosition, scrollTo } from 'app_vodafone_ziggo/util/util';
import { removeHash } from 'app_vodafone_ziggo/util/urlUtils';
import Collapse from 'bootstrap/js/src/collapse';

window.bs = window.bs || {};
window.bs.Collapse = Collapse;

/**
 * @private scrollToQuestion
 */
function scrollToQuestion() {
    if (window.location.hash) {
        const hash = window.location.hash;
        const collapseEl = document.querySelector(hash);
        if (!collapseEl) return;

        const elementsToExpand = [collapseEl];

        // If we're inside a collapsable group, expand the group
        // Otherwise we can't show the child collapsable item
        const group = collapseEl.closest('.faq-items.collapse');
        if (group) elementsToExpand.push(group);

        // Auto expand the collapsable items on page load
        elementsToExpand.forEach((item) => {
            /* eslint-disable-next-line no-new */
            new window.bs.Collapse(item, {
                toggle: true
            });
        });

        const togglerEl = document.querySelector(`[data-bs-target="${hash}"]`);

        // Finally, scroll to the question
        const elementPosition = togglerEl ? getPosition(togglerEl) : getPosition(collapseEl);

        // Scroll after a really short timeout for some edgecases where a browser's autoscroll is
        // firing after our custom `scrollTo` functionality. This might have something to do with the
        // callstack, so make sure our `scrollTo` is fired last.
        setTimeout(() => {
            scrollTo(elementPosition);
        }, 1);
    }
}

/**
 * @private updateHash
 * @param {HTMLElement} target - The clicked element
 */
function updateHash(target) {
    const hashValue = target.getAttribute('data-bs-target');
    const urlWithoutHash = window.location.href.split('#')[0];

    // Use replaceState instead of window.location.hash. Otherwise, the user would have a lot of history
    // entries after clicking various questions. Also the browser would auto scroll on hash change.
    window.history.replaceState({}, document.title, urlWithoutHash + hashValue);
}

/**
 * @private onCollapseToggle
 * @param {event} event - The click event
 */
function onCollapseToggle(event) {
    const { target } = event;

    if (target.getAttribute('aria-expanded') === 'true') {
        updateHash(target);
    } else {
        removeHash();
    }
}

window.addEventListener('load', scrollToQuestion);

[...document.querySelectorAll('.btn-faq-item')].forEach((button) => {
    button.addEventListener('click', onCollapseToggle);
});
