'use strict';

import { CONST, documentReady, addEvent } from 'app_vodafone_ziggo/util/util';

/**
 * @constant {Object} selectors - Selectors used for the Prompt
 * @property {string} promptOpen - .js-btn-prompt-open
 * @property {string} promptCancel - .js-btn-prompt-cancel
 * @property {string} promptConfirm - .js-btn-prompt-confirm
 */
const selectors = {
    prompt: '.js-prompt',
    promptOpen: '.js-btn-prompt-open',
    promptCancel: '.js-btn-prompt-cancel',
    promptConfirm: '.js-btn-prompt-confirm',
    chatPromptOpen: '.dimelo_chat_show_when_offline',
    chatClosedPrompt: '#bscan-chat-closed'
};

/**
 * @private promptOpen
 * @param {event} event - The click event
 */
function promptOpen(event) {
    const button = event.target;
    const target = button.getAttribute(CONST.attributes.target);
    const prompt = document.querySelector(target);

    if (prompt) {
        prompt.classList.add(CONST.classes.active);
    }
}

/**
 * @private promptClose
 * @param {event} event - The click event
 * @param {boolean} confirm - If the "confirm" button has been clicked
 */
function promptClose(event, confirm) {
    if (!confirm) {
        event.preventDefault();
    }

    const button = event.target;
    const prompt = button.closest(selectors.prompt);

    if (prompt) {
        prompt.classList.remove(CONST.classes.active);
        button.blur(); // Remove the focus on the button, necessary for BScan keyboard navigation
    }
}

documentReady(() => {
    addEvent(document, CONST.events.click, selectors.promptOpen, promptOpen);
    addEvent(document, CONST.events.click, selectors.promptCancel, (event) => {
        promptClose(event, false);
    });
    addEvent(document, CONST.events.click, selectors.promptConfirm, (event) => {
        promptClose(event, true);
    });

    addEvent(document, CONST.events.click, selectors.chatPromptOpen, (event) => {
        const button = event.target;

        if (!button.getAttribute(CONST.attributes.target)) {
            button.setAttribute(CONST.attributes.target, selectors.chatClosedPrompt);
        }

        promptOpen(event);
    });
});
