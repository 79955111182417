'use strict';

/**
 * @constant {Object} ADDRESS_MAPPING - Mapping company lookup response with form fields.
 */
const ADDRESS_MAPPING = {
    postalCode: 'dwfrm_checkoutDetails_company_postcode',
    houseNumber: 'dwfrm_checkoutDetails_company_housenumber',
    houseNumberAddition: 'dwfrm_checkoutDetails_company_addition',
    street: 'dwfrm_checkoutDetails_company_address1',
    city: 'dwfrm_checkoutDetails_company_city',
    country: 'dwfrm_checkoutDetails_company_country',
    foundationDate: 'dwfrm_checkoutDetails_company_creationDate',
    legalForm: 'dwfrm_checkoutDetails_company_legalform'
};

/**
 * @constant {Object}  LEGAL_FORM - Mapping legal form field values with legal form types.
 */
const LEGAL_FORM = {
    BV: '1',
    NV: '2',
    VOF: '3',
    Eenmanszaak: '4',
    CV: '5',
    Maatschap: '6',
    Vereniging: '7',
    'onderlinge waarborgmaatschappij': '8',
    Stichting: '9'
};

/**
 * @public startsWithNumber
 * @description tests an input against a regex to evaluate if it starts with a number
 * @param {string} value the value to test
 * @returns {boolean} whether it starts with a number or not
 */
function startsWithNumber(value) {
    const regex = /^\d/;
    return regex.test(value);
}

/**
 * @private getCompanyRelatedFields
 * @description converts a group of selectors into elements
 * @returns {Array} an array of elements
 */
function getCompanyRelatedFields() {
    return [
        '.js-kvk-number',
        '.js-creation-date',
        '[name="dwfrm_checkoutDetails_company_legalform"]',
        '[name="dwfrm_checkoutDetails_company_country"]',
        '[name="dwfrm_checkoutDetails_company_companyName"]',
        '[name="dwfrm_checkoutDetails_company_postcode"]',
        '[name="dwfrm_checkoutDetails_company_housenumber"]',
        '[name="dwfrm_checkoutDetails_company_addition"]',
        '[name="dwfrm_checkoutDetails_company_address1"]',
        '[name="dwfrm_checkoutDetails_company_city"]'
    ]
        .map((selector) => {
            return document.querySelector(selector);
        })
        .filter((item) => item); // Filter out null values
}

/**
 * @public clearCompanyRelatedFields
 * @description clears all company related fields and sets correct classes
 */
function clearCompanyRelatedFields() {
    getCompanyRelatedFields().forEach((element) => {
        // eslint-disable-next-line default-case
        switch (element.tagName) {
            case 'INPUT':
                element.value = '';
                element.classList.add('empty');
                break;
            case 'SELECT':
                element.selectedIndex = 0;

                if (element.classList.contains('js-kvk-companies') && element.options.length) {
                    [...element.options].forEach((option) => {
                        if (option.value !== '' && !option.hasAttribute('disabled')) option.remove();
                    });
                }
                break;
        }
    });
}

/**
 * @public getCompanyDetailsUrl
 * @description returns a company's details URL given a list of companies and a KVK number
 * @param {array} companies an array of companies to search in
 * @param {string} kvkNumber a KVK number to search for in the list of companies passed in the first parameter'
 * @returns {string} the details URL for the given KVK number
 */
function getCompanyDetailsUrl(companies, kvkNumber) {
    return companies.find((company) => company.kvkNumber === kvkNumber).detailsUrl;
}

/**
 * @function getKeyByValue
 * @private
 * @param {Array} map - The Array to search in
 * @param {string} value - The value used to find a key
 * @returns {string | null} - Returns the key when found or null when not found.
 */
function getKeyByValue(map, value) {
    return Object.keys(map).find((key) => map[key] === value) || null;
}

/**
 * @function onCompanyChange
 * @private
 * @description when fn populateCompanyNameField in VZCompanySearch.js fires a change event, this function handles populating all related fields
 * @param {ChangeEvent} evt - Change event on company selector
 */
function onCompanyChange(evt) {
    const checkedOption = evt.target.querySelector('option:checked');
    if (!checkedOption) return;
    const val = checkedOption.getAttribute('data-value');
    if (!val) return;

    try {
        const address = JSON.parse(decodeURI(val)).address;
        Object.keys(ADDRESS_MAPPING)
            .map((key) => document.getElementById(ADDRESS_MAPPING[key]))
            .filter((el) => el) // Filter out null values
            .forEach((element) => {
                const key = getKeyByValue(ADDRESS_MAPPING, element.id);
                const value = key === 'legalForm' ? LEGAL_FORM[address[key]] : address[key];
                const isNoValidOption = element.options && ![...element.options].find((option) => option.value === value);
                const allowManuallyEntry = [ADDRESS_MAPPING.legalForm, ADDRESS_MAPPING.foundationDate].includes(element.id);

                if (isNoValidOption && allowManuallyEntry) {
                    [...element.options].forEach((option) => {
                        if (option.value.length > 0) option.removeAttribute('disabled');
                    });
                }

                if ((!value || isNoValidOption) && allowManuallyEntry) {
                    element.setAttribute('read-only', 'false');
                    return;
                }

                element.value = value;
            });
    } catch (e) {
        console.error(e);
    }
}

export { startsWithNumber, clearCompanyRelatedFields, getCompanyDetailsUrl, onCompanyChange };
