'use strict';

/**
 * @constant {string} CAPS_MASK_ATTRIBUTE - Attribute value for caps mask
 */
export const CAPS_MASK_ATTRIBUTE = 'caps';

/**
 * @class CapsMask
 * @param {HTMLInputElement} element - An input field
 */
export class CapsMask {
    constructor(element) {
        element.addEventListener('input', this.onInput.bind(element));
        element.addEventListener('change', this.onInput.bind(element));
    }

    onInput() {
        const position = this.selectionStart;
        this.value = this.value.toUpperCase();
        this.setSelectionRange(position, position);
    }
}
