'use strict';

/**
 * @public regex
 * @param {HTMLInputElement} element - The element used to set the regex.
 */
export default function regex(element) {
    Object.defineProperty(element, 'regex', {
        enumerable: false,
        configurable: true,
        writable: true,
        value: element.required ? new RegExp(element.pattern) : new RegExp() // Pattern or empty when not mandatory
    });
}
