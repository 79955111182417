'use strict';

import 'Components/form/VZSelect';
import 'Components/form/VZInputField';
import initLetsTalk from 'app_business_scan/steps/letsTalk'; // This is used to fetch the timeslots once a date has been selected
import { validateForm } from 'app_vodafone_ziggo/util/validation';
import { checkGA } from './common.js';
import CONST from 'Util/constants';
import moment from 'moment';
import { validateField } from 'app_vodafone_ziggo/util/validation';

export default class VZLeadForm extends HTMLElement {
    constructor() {
        super();
        this.letsTalkHandler = null;
    }

    connectedCallback() {
        if ((window.bs && window.bs.Modal) || document.readyState === 'complete') {
            this.initLeadform();
        } else {
            document.addEventListener('DOMContentLoaded', this.initLeadform.bind(this));
        }
    }

    /**
     * Initializes the lead form. It sets up the modal button, submit button, form, and lead modal.
     */
    initLeadform() {
        this.leadModalElement = this.querySelector('#lead-modal');
        this.leadModal = this.leadModalElement ? new window.bs.Modal(this.leadModalElement) : null;
        this.form = this.querySelector('form');
        this.submitBtn = this.form.querySelector('.js-lead-form-submit');
        this.setPlpBasket = this.setPlpBasket.bind(this);

        if (this.leadModal !== null) {
            // Add event listeners for both show and hide events of the modal
            this.leadModalElement.addEventListener('show.bs.modal', this.onModalShow.bind(this));
            this.leadModalElement.addEventListener('hidden.bs.modal', this.onModalHidden.bind(this));
        } else {
            this.initLetsTalk();
        }

        this.submitBtn.addEventListener('click', this.submit.bind(this));
        this.updateLeadSource();
        this.updateActionUrl();
        this.spinner = document.querySelector('vz-spinner') || new window.VZSpinner();

        // Validate preselected phone prefix field
        const prefixField = this.form.querySelector('.js-leadform-phoneprefix');
        if (prefixField) {
            validateField(prefixField);
        }
    }

    disconnectedCallback() {
        this.submitBtn.removeEventListener('click', this.submit);
    }

    open() {
        if (this.leadModal != null) {
            this.leadModal.show();
            if (document.querySelector('.js-advice-json')?.value) {
                const customEvent = new CustomEvent('modalLoaded', {
                    detail: {
                        modal: this.leadModal._element // eslint-disable-line no-underscore-dangle
                    }
                });
                document.dispatchEvent(customEvent);

                // additional check for GA clientId for Business Scan
                // because the form is injected without page reload
                checkGA();
            }
            // set PLP products
            this.setPlpBasket();
        }
    }

    initLetsTalk() {
        // Clean up previous handler if it exists
        this.cleanupLetsTalk();

        // Initialize new LetsTalk handler
        this.letsTalkHandler = initLetsTalk(this.leadModalElement || this);
    }

    cleanupLetsTalk() {
        if (this.letsTalkHandler) {
            this.letsTalkHandler();
            this.letsTalkHandler = null;
        }
    }

    onModalShow() {
        this.initLetsTalk();
    }

    onModalHidden() {
        this.cleanupLetsTalk();
    }

    disconnectedCallback() {
        this.submitBtn.removeEventListener('click', this.submit);
        this.cleanupLetsTalk();
    }

    setPlpBasket() {
        const plpSidebar = document.querySelector('vz-plp-sidebar');
        if (plpSidebar) {
            const plpBasket = plpSidebar.getAttribute('plp-basket');
            this.form.querySelector('.js-plp-basket').value = plpBasket;
        }
    }

    parseFormFields() {
        return new Promise((resolve) => {
            const timeSlot = this.querySelector('#timeslot');
            this.form.querySelector('.js-scheduler-date-time').value = new Date(timeSlot[timeSlot.selectedIndex].value).toISOString();
            resolve();
        });
    }

    /**
     * Updates the lead source with a new value from the 'data-lead-source' attribute of the element.
     *
     * @private updateLeadSource
     * @returns {void}
     */
    updateLeadSource() {
        const leadSourceName = this.dataset.leadSource;
        if (leadSourceName) {
            this.form.querySelector('input[name=leadSourceName]').value = leadSourceName;
        }
    }

    /**
     * Updates action url for the Business Scan lead form
     *
     * @private updateActionUrl
     * @returns {void}
     */
    updateActionUrl() {
        const actionUrl = this.dataset.actionUrl;
        if (actionUrl) {
            this.form.action = actionUrl;
        }
    }

    /**
     * Sets the selected resource ID in the lead form based on the selected appointment time.
     *
     * This method retrieves the selected appointment time from a dropdown select element and
     * sets the corresponding resource ID in a form input field.
     *
     * @returns {void}
     */
    setSelectedResourceId() {
        const timeSelector = this.form.querySelector('.js-select-appointment-time');
        const selectedTimeOption = timeSelector ? timeSelector.options[timeSelector.selectedIndex] : null;
        if (selectedTimeOption) {
            this.form.querySelector('.js-lead-form-resource-input').value = selectedTimeOption.dataset.resourceId;
        }
    }

    /**
     * Gets current time from moment.js library.
     *
     * @returns {void}
     */
    getCurrentTime() {
        const now = moment().format();
        this.form.querySelector('input[name=currentDate]').value = now;
    }

    /**
     * @private onSubmit
     * @param {ChangeEvent} evt - submit event
     */
    async submit(evt) {
        evt.preventDefault();
        this.submitBtn.setAttribute(CONST.attributes.disabled, CONST.attributes.disabled);
        this.submitBtn.classList.add(CONST.attributes.disabled);
        this.spinner.start();
        this.setSelectedResourceId();
        this.getCurrentTime();
        if (validateForm(this.form)) {
            await this.parseFormFields();
            this.form.submit();
        } else {
            this.spinner.stop();
            this.submitBtn.removeAttribute(CONST.attributes.disabled);
            this.submitBtn.classList.remove(CONST.attributes.disabled);
        }
    }
}

if (!window.customElements.get('vz-lead-form')) {
    window.customElements.define('vz-lead-form', VZLeadForm);
}
