/**
 * Initializes the "Let's Talk" functionality for appointment scheduling
 * @param {HTMLElement} modal - The modal element containing the appointment form
 * @returns {Function} A cleanup function to remove event listeners
 */
export default function initLetsTalk(modal) {
    const spinner = document.querySelector('vz-spinner') || new window.VZSpinner();
    const selectDay = modal.querySelector('.js-appointment-day');
    const selectTime = modal.querySelector('.js-appointment-time');

    /**
     * Handles the date change event
     * @param {Event} event - The change event object
     */
    function handleChangeDate(event) {
        const datestring = `${event.detail.date.getFullYear()}-${event.detail.date.getMonth() + 1}-${event.detail.date.getDate()}`;
        const {
            target: {
                dataset: { url }
            }
        } = event;
        const selectDayErrorMessageElement = selectDay.parentElement.querySelector('.error-msg');

        // Needs to be queried again every time otherwise the reference to the element when trying to disable it is not valid.
        const selectAppointmentTimeElement = selectTime.querySelector('.js-select-appointment-time');

        // Remove options and disable element if the date value is not present.
        if (!datestring && selectAppointmentTimeElement) {
            [...selectAppointmentTimeElement.options].filter((option) => !option.hasAttribute('disabled')).forEach((option) => option.remove());
            selectAppointmentTimeElement.classList.remove('valid');
            selectAppointmentTimeElement.setAttribute('disabled', true);
            return;
        }

        spinner.start();
        fetch(`${url}${datestring}`)
            .then((response) => {
                if (response.status === 200 && selectDayErrorMessageElement) {
                    selectDayErrorMessageElement.remove();
                }
                return response.text();
            })
            .then((response) => {
                selectTime.innerHTML = response;
            })
            .finally(() => {
                spinner.stop();
            });
    }

    selectDay.addEventListener('changeDate', handleChangeDate);

    return () => {
        selectDay.removeEventListener('changeDate', handleChangeDate);
    };
}
