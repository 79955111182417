'use strict';

import * as throttle from 'lodash/throttle';

/**
 * @function getVerticalScrollPercentage
 * @returns {number} the percentage
 */
function getVerticalScrollPercentage() {
    const parentNode = document.body.parentNode;
    return ((document.body.scrollTop || parentNode.scrollTop) / (parentNode.scrollHeight - parentNode.clientHeight)) * 100;
}

/**
 * @function isWithin24Hours
 * @returns {boolean} Whether the modal was closed within 24 hours
 */
function isWithin24Hours() {
    const then = new Date(localStorage.getItem('modalCloseDate'));
    const now = new Date();

    const msBetweenDates = Math.abs(then.getTime() - now.getTime());

    // convert ms to hours                     min  sec   ms
    const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
    return hoursBetweenDates < 24;
}

/**
 * @function closeModal
 * @param {Event} evt - Click event
 */
function closeModal(evt) {
    // hide modal
    evt.currentTarget.parentNode.removeChild(evt.target);
    localStorage.setItem('modalCloseDate', new Date());
}

/**
 * @function getAgentStatus
 * @returns {string} Checks if the chat is online
 */
function getAgentStatus() {
    return document.querySelector('.dimelo_chat_mode_online') ? 'online' : 'offline';
}

/**
 * @function onModalClick
 * @param {Event} Click Event
 */
function onModalClick({ target }) {
    if (!target.classList.contains('btn-close')) {
        if (getAgentStatus() === 'online') {
            document.querySelector('.js-btn-contactmodal').click();
        } else {
            document.querySelector('vz-contactmodal')?.show();
        }
    }
}

/**
 * @function showModal
 */
function showModal() {
    const engagementModal = document.querySelector('.engagement-modal');
    engagementModal.classList.remove('d-none');
    engagementModal.addEventListener('click', onModalClick);
    document.querySelector('.engagement-modal .btn-close').addEventListener('click', closeModal);
}

/**
 * @function modalThreshold
 */
const modalThreshold = throttle(() => {
    if (getVerticalScrollPercentage() > 75) {
        document.removeEventListener('scroll', modalThreshold);
        setTimeout(showModal, 120_000); // 2 minutes delay
    }
}, 100);

/**
 * @function shakeThreshold
 */
const shakeThreshold = throttle(() => {
    if (getVerticalScrollPercentage() > 30) {
        [...document.querySelectorAll('.dimelo_chat_show_when_online,.dimelo_chat_show_when_offline')].forEach((button) => button.classList.add('shake'));
        document.removeEventListener('scroll', shakeThreshold);

        if (!isWithin24Hours()) {
            document.addEventListener('scroll', modalThreshold);
        }
    }
}, 100);

/**
 * @function startEngagement
 */
(function startEngagement() {
    const pageView = Math.abs(localStorage.getItem('pageView') || 0) + 1;
    localStorage.setItem('pageView', pageView);

    if (pageView === 2) {
        document.addEventListener('scroll', shakeThreshold);
    }
})();
