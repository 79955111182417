'use strict';

/**
 * @public validate
 * @param {HTMLInputElement} element - The element used to validate.
 */
export default function validate(element) {
    Object.defineProperty(element, 'valid', {
        enumerable: true,
        configurable: false,
        set: (value) => {
            if (value) {
                element.classList.add('valid');
                element.classList.remove('invalid');
            } else {
                element.classList.add('invalid');
                element.classList.remove('valid');
            }
        }
    });
}
