if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        if (document.documentElement.dataset.serviceWorkerEnabled === 'true') {
            navigator.serviceWorker.register('/sw.js', { scope: '.' }).catch((e) => {
                console.error('Serviceworker - Registration failed with error '.concat(e));
            });
        } else {
            navigator.serviceWorker.getRegistrations().then((serviceWorkers) => {
                serviceWorkers.forEach((workerInstance) => {
                    workerInstance.unregister();
                });
            });
        }
    });
}
