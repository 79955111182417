'use strict';

/**
 * @constant {string} DOCUMENT_NUMBER - Input field for document number
 */
const DOCUMENT_NUMBER = 'dwfrm_checkoutDetails_identification_documentNumber';

/**
 * @public documentNumber
 * @param {HTMLInputElement} element - Document Number input field
 */
export default function documentNumber(element) {
    if (element.name !== DOCUMENT_NUMBER) return;

    element.closest('form').addEventListener('identificationTypeChanged', (evt) => {
        // First reset regex state due to /g entries
        // https://stackoverflow.com/questions/2630418/javascript-regex-returning-true-then-false-then-true-etc/2630538#2630538
        element.pattern = evt.detail.regex.toString().replace('/^', '^').replace('$/gi', '$');
        element.regex.lastIndex = 0;
        element.regex = new RegExp(element.pattern);
        if (element.value.length > 0) {
            element.dispatchEvent(new CustomEvent('input'));
            element.dispatchEvent(new CustomEvent('blur'));
        }
    });

    const originalErrorMsg = element.dataset.patternError;
    element.addEventListener('input', () => {
        // Reset to original error message
        element.dataset.patternError = originalErrorMsg;
        if (element.dataset.selectedId && element.dataset.selectedId.includes('verblijfsdocument')) {
            if (element.value.toLowerCase().startsWith('x00')) element.dataset.patternError = element.dataset.patternX00Error;
            if (element.value.toLowerCase().startsWith('v')) element.dataset.patternError = element.dataset.patternVError;
        }
    });
}
