'use-strict';

import clsx from 'clsx';

export default class ModalHelper {
    /**
     * @function defaultOptions
     * @description Returns the default configuration options for modals.
     */
    static get defaultOptions() {
        return {
            headerBorder: true,
            backdrop: 'static',
            keyboard: false,
            closeBtn: true
        };
    }

    /**
     * @function getDefaultModalHtml
     * @description Interpolates variables into the template and returns the modal HTML.
     * @param {Object} configurationOptions - An object containing configuration options for the modal.
     * @returns {string} A string representing the default HTML for modals.
     */
    static getDefaultModalHtml(configurationOptions) {
        const {
            bodyText,
            bodyTitle,
            closeBtn,
            closeBtnUrl,
            headerBorder,
            iconName,
            isSecondaryBtnDismiss,
            primaryBtnUrl,
            primaryBtnTarget,
            primaryBtnText,
            secondaryBtnTarget,
            secondaryBtnText,
            secondaryBtnUrl,
            titleText
        } = configurationOptions;
        const { modalFooterClasses, tertiaryBtnTarget, tertiaryBtnText, tertiaryBtnUrl } = configurationOptions;

        // Clsx is a small package (~250 bytes) which allows for the creation of conditional classes.
        const headerClasses = clsx(!headerBorder && 'border-0');

        // prettier-ignore
        return `
            <div class="modal-dialog js-modal-dialog mx-2 mx-sm-auto w-100">
                <div class="modal-content">
                    <div class="modal-header pb-2 d-flex align-items-center ${headerClasses}">
                        ${iconName?.length ? `<i alt="icon" class="icon icon-${iconName} modal-header-icon fs-3"></i>` : ''}
                        <div class="d-flex flex-grow-1">
                            <h5 class="modal-title text-black-aem">
                                ${titleText}
                            </h5>
                        </div>
                        ${closeBtn && closeBtnUrl ? `<a href="${closeBtnUrl}" class="btn btn-close" role="button"></a>` : ''}
                        ${closeBtn && !closeBtnUrl ? '<button type="button" class="btn btn-close" data-bs-dismiss="modal" aria-label="Sluiten"></button>' : ''}
                    </div>

                    <div class="modal-body">
                        ${bodyTitle?.length ? `<p class="text-primary h4">${bodyTitle}</p>` : ''}
                        ${bodyText?.length ? `<p>${bodyText}</p>` : ''}
                    </div>

                    <div class="modal-footer ${modalFooterClasses || ''}">
                        ${(primaryBtnUrl?.length && primaryBtnText?.length) ? `<a href="${primaryBtnUrl}" role="button" class="btn btn-primary flex-grow-1 btn-modal" title="${primaryBtnText}" ${primaryBtnTarget ? `target="${primaryBtnTarget}"` : ''}>
                            ${primaryBtnText}
                        </a>` : ''}
                        ${isSecondaryBtnDismiss ? `<button type="button" class="btn btn-tertiary btn-modal" data-bs-dismiss="modal">${secondaryBtnText}</button>` : `<a href="${secondaryBtnUrl}" role="button" class="btn btn-tertiary flex-grow-1 btn-modal" title="${secondaryBtnText}" ${secondaryBtnTarget ? `target="${secondaryBtnTarget}"` : ''}>
                            ${secondaryBtnText}
                        </a>`}
                        ${tertiaryBtnText ? `<a class="border-0 w-100 text-center mt-2" href="${tertiaryBtnUrl}" ${tertiaryBtnTarget ? `target="${tertiaryBtnTarget}"` : ''}>${tertiaryBtnText}</a>` : ''}
                    </div>
                </div>
            </div>
        `;
    }

    /**
     * @function renderAbandonConfigurationModal
     * @description Will add an onclick listener to all 'Wijzigen' cta's inside the minicart. When clicked on, the abandonConfiguration modal will show up.
     */
    // eslint-disable-next-line
    renderAbandonConfigurationModal() {
        const editButtons = [...document.querySelectorAll('.js-edit-button')];
        editButtons.forEach((editButton) => {
            editButton.addEventListener('click', () => {
                const { abandonConfigurationModalId } = editButton.dataset;

                const abandonConfigurationModal = document.getElementById(abandonConfigurationModalId);
                abandonConfigurationModal?.render();
            });
        });
    }
}
