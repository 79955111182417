'use strict';

import { CapsMask } from './caps';

/**
 * @constant {string} POSTCODE_MASK_ATTRIBUTE - Attribute value for postcode mask
 */
const POSTCODE_MASK_ATTRIBUTE = 'postcode';

/**
 * @constant {integer} BACKSPACE_KEYCODE
 */
const BACKSPACE_KEYCODE = 8;

/**
 * @constant {integer} DELETE_KEYCODE
 */
const DELETE_KEYCODE = 46;

/**
 * @constant {Array} UNDO_KEYS
 */
const UNDO_KEYS = [BACKSPACE_KEYCODE, DELETE_KEYCODE, 'backspace', 'delete'];

/**
 * @class PostCodeMask
 * @param {HTMLInputElement} element - The input field to put the postcode mask on.
 */
class PostCodeMask extends CapsMask {
    constructor(element) {
        super(element);
        this.element = element;
        this.onKeyUp = this.onKeyUp.bind(this);

        element.addEventListener('keyup', this.onKeyUp);
    }

    /**
     * @private onKeyUp
     * @param {KeyboardEvent} evt - On key up event
     */
    onKeyUp(evt) {
        const key = evt.key && typeof evt.key !== 'number' ? evt.key.toLocaleLowerCase() : evt.key || null;
        if (key && UNDO_KEYS.some((k) => k === key)) {
            if (this.element.value.length === 5) {
                this.element.value = this.element.value.substring(0, 4);
            }
            return;
        }

        // Mask the input value for postcodes using a regular expression.
        this.element.value = this.element.value.replace(/(^\d{4})(\w{0,2}$)/, '$1 $2');
    }
}

export { PostCodeMask, POSTCODE_MASK_ATTRIBUTE };
