'use strict';

import { validateField } from 'app_vodafone_ziggo/util/validation';

const selectors = {
    chooserInviteWrapper: '.js-chooser-invite',
    chooserInviteCheckbox: '.js-chooser-invite-toggle',
    chooserInviteContent: '.js-chooser-invite-content'
};

/**
 * Toggles the visibility and disabled state of form fields based on the state of the invite toggle.
 * @param {HTMLElement} chooserInviteToggle - The invite toggle element.
 * @param {HTMLElement} chooserInviteContent - The invite content element.
 */
function toggleContentAndFields(chooserInviteToggle, chooserInviteContent) {
    chooserInviteContent.classList.toggle('show', !chooserInviteToggle.checked);
    const formFields = chooserInviteContent.querySelectorAll('.form-control, .custom-select');
    formFields.forEach((field) => {
        field.disabled = chooserInviteToggle.checked;

        // Validate the prefilled phone prefix field when showing the form
        if (field.classList.contains('js-phone-prefix')) {
            validateField(field);
        }
    });
}

/**
 * Toggles the visibility of the chooser invite section based on the provided condition.
 * @param {boolean} condition - The condition to toggle the visibility.
 */
function showChooserInviteSection(condition) {
    const chooserInviteSection = document.querySelector(selectors.chooserInviteWrapper);
    const chooserInviteCheckbox = document.querySelector(selectors.chooserInviteCheckbox);

    // Set the state of the checkbox to checked if the Chooser Invite section gets hidden and collapse the form
    if (!condition && chooserInviteCheckbox) {
        chooserInviteCheckbox.checked = true;
    }

    if (chooserInviteSection) {
        chooserInviteSection.classList.toggle('d-none', !condition);
    }
}

/**
 * Initializes the chooser invite by setting up the toggle functionality.
 */
function initChooserInvite() {
    const chooserInviteToggle = document.querySelector(selectors.chooserInviteCheckbox);
    const chooserInviteContent = document.querySelector(selectors.chooserInviteContent);

    if (chooserInviteToggle) {
        toggleContentAndFields(chooserInviteToggle, chooserInviteContent);
        chooserInviteToggle.addEventListener('change', () => toggleContentAndFields(chooserInviteToggle, chooserInviteContent));
    }
}

export { initChooserInvite, showChooserInviteSection };
