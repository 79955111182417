'use strict';

import decorators from 'Decorators/index';
import { validate } from 'app_vodafone_ziggo/util/validation';

/**
 * @class InputField
 * @param {HTMLInputElement} element - The element to create an instance on
 */
class VZInputField extends HTMLInputElement {
    constructor() {
        super();
        this.validatePattern = this.validatePattern.bind(this);
        this.setFieldStatus = this.setFieldStatus.bind(this);
        this.onPaste = this.onPaste.bind(this);

        this.addEventListener('input', this.validatePattern);
        this.addEventListener('input', this.setFieldStatus);
        this.addEventListener('changeDate', this.setFieldStatus);
        this.addEventListener('changeDate', validate);
        this.addEventListener('paste', this.onPaste);
        this.validate = validate;

        decorators.readonly(this);
        decorators.regex(this);
        decorators.valid(this);
        decorators.documentNumber(this);

        if (this.hasAttribute('data-mask')) {
            decorators.mask(this);
        }

        if (this.hasAttribute('data-prevent')) {
            decorators.preventCharacters(this);
        }

        if (this.type === 'password') {
            decorators.password(this);
        }

        // Set initial state
        this.setFieldStatus();

        if (this.type === 'radio' || this.type === 'checkbox') {
            this.addEventListener('change', validate);
        } else {
            this.addEventListener('blur', validate);
        }

        this.closest('form').addEventListener('submit', this.disableExcludedFields);
    }

    onPaste(evt) {
        // Execute these methods at the end of the callstack, otherwise `field.value` will be the value before the paste.
        setTimeout(() => {
            this.setFieldStatus();
            validate(evt);
        }, 1);
    }

    disableExcludedFields() {
        if (this.hasAttribute('data-exclude')) {
            this.setAttribute('disabled', 'disabled');
        }
    }

    /**
     * @private setFieldStatus
     * @description
     * Sets an empty class when the element is empty for styling purposes.
     */
    setFieldStatus() {
        const hasValue = this.value.length > 0;
        const placeholderIsShown = this.constructor.placeholderActive(this);
        if (hasValue) {
            this.classList.remove('empty');
        } else if (placeholderIsShown) {
            // input[type="number"] accepts dash and dots but returns no value for that.
            this.classList.add('empty');
        }
    }

    /**
     * @private validatePattern
     * @description
     * Checks whether the value is valid based on pattern
     */
    validatePattern() {
        this.valid = this.regex.test(this.value);
    }

    /**
     * @function placeholderActive
     *
     * @param {HTMLInputElement} element - Input element
     * @return {boolean} whether the placeholder is shown or not
     */
    static placeholderActive(element) {
        const selector = `${element.nodeName.toLowerCase()}[name="${element.name}"]:placeholder-shown`;
        return !!document.querySelector(selector);
    }
}

export default window.customElements.get('vz-input') || VZInputField;
if (!window.customElements.get('vz-input')) {
    window.customElements.define('vz-input', VZInputField, { extends: 'input' });
}
