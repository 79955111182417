'use strict';

import documentNumber from './documentNumber';
import idType from './idType';
import mask from './mask';
import password from './password';
import preventCharacters from './preventCharacters';
import readonly from './readonly';
import regex from './regex';
import valid from './valid';

export default {
    documentNumber,
    idType,
    mask,
    password,
    preventCharacters,
    readonly,
    regex,
    valid
};
