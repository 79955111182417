'use strict';

import * as debounce from 'lodash/debounce';
import { removeChildNodes, removeAccentCharacters } from 'Util/util';
import VZSpinner from 'Components/VZSpinner';
import { startsWithNumber, clearCompanyRelatedFields, getCompanyDetailsUrl } from './helpers/companySearchHelpers';
import { appendParamToURL, appendCsrfToUrl } from 'Util/urlUtils';
import moment from 'moment-timezone';
import { showChooserInviteSection } from 'Components/chooserInvite';

export default class VZCompanySearch extends HTMLElement {
    constructor() {
        super();
        this.inputField = this.querySelector('.js-company-search');
        this.form = this.querySelector('.js-company-search-section');
        this.result = this.querySelector('.js-company-result');
        this.searchResults = this.querySelector('.js-company-search-results');
        this.kvkNumberField = this.querySelector('.js-kvk-number');
        this.companySelector = this.querySelector('.js-kvk-companies');
        this.chooserInviteElement = this.querySelector('.js-chooser-invite');
        this.companyInformationCards = ['companyInformation', 'paymentInformation', 'checkoutNavigation', 'installationInformation']
            .map((item) => document.getElementById(item))
            .filter((item) => item);

        this.minQueryLengthText = parseInt(this.inputField.dataset.minQueryLengthText, 10);
        this.minQueryLengthNumber = parseInt(this.inputField.dataset.minQueryLengthNumber, 10);
        this.tradenameEndpoint = this.inputField.dataset.tradenameUrl;
        this.kvkEndpoint = this.inputField.dataset.kvkUrl;
        this.hasExistingKvk = this.getAttribute('has-existing-kvk') === 'true';
        this.isLeadForm = this.getAttribute('is-lead-form') === 'true';
        this.spinner = document.querySelector('vz-spinner') || new VZSpinner();

        this.onEdit = this.onEdit.bind(this);
        this.onKeyup = this.onKeyup.bind(this);
        this.onSearchResultClick = this.onSearchResultClick.bind(this);
        this.handleSearchResultClick = this.handleSearchResultClick.bind(this);
        this.populateKvkField = this.populateKvkField.bind(this);
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.fetchExistingKvk = this.fetchExistingKvk.bind(this);
        this.getEndpoint = this.getEndpoint.bind(this);
        this.onExistingKvkFetch = this.onExistingKvkFetch.bind(this);
    }

    /**
     * @function observedAttributes
     * @description observes the mentioned attribute and fires attributeChangedCallback when the value has changed
     * @returns {Attr} the companies attribute. This attribute's value is an encoded JSON string so should be decoded when using.
     */
    static get observedAttributes() {
        return ['companies'];
    }

    connectedCallback() {
        this.inputField.addEventListener('keyup', debounce(this.onKeyup, 500));
        this.inputField.addEventListener('keydown', VZCompanySearch.onKeydown.bind(this));
        this.searchResults.addEventListener('click', this.onSearchResultClick);
        document.addEventListener('click', this.handleDocumentClick);

        if (this.kvkNumberField && this.kvkNumberField.value.length > 0 && this.hasExistingKvk) {
            window.addEventListener('load', this.fetchExistingKvk);
        }

        if (this.querySelector('.js-company-edit')) this.querySelector('.js-company-edit').addEventListener('click', this.onEdit);
    }

    disconnectedCallback() {
        this.inputField.removeEventListener('keyup', this.onKeyup, 500);
        this.inputField.removeEventListener('keydown', VZCompanySearch.onKeydown);
        this.searchResults.removeEventListener('click', this.onSearchResultClick);
        document.removeEventListener('click', this.handleDocumentClick);
        if (this.querySelector('.js-company-edit')) this.querySelector('.js-company-edit').removeEventListener('click', this.onEdit);
    }

    /**
     * @function attributeChangedCallback
     * @description fired when an observed attribute changes
     * @param {string} name the name of the attribute
     * @param {any} oldValue the previous value of the attribute
     * @param {any} newValue the new value of the attribute
     */
    attributeChangedCallback(name, oldValue, newValue) {
        if (name === 'companies' && this.hasExistingKvk) {
            this.onExistingKvkFetch(newValue);
        }
    }

    /**
     * @function handleDocumentClick
     * @private
     * @description handles closing the result list if a user clicks somewhere outside it and reopens if input is clicked again
     * @param {Event} evt the click event
     */
    handleDocumentClick(evt) {
        const { target } = evt;
        const resultsOpen = this.searchResults.classList.contains('open');
        const resultsContainer = this.searchResults.querySelector('.js-company-search-results-container');
        const hasInput = this.inputField.value.length > 0;

        if (!hasInput || !resultsContainer || !resultsOpen) {
            return;
        }
        if (!target.classList.contains('.js-company-search-results')) {
            resultsContainer.classList.add('d-none');
        }
        if (target.classList.contains('js-company-search')) {
            resultsContainer.classList.remove('d-none');
        }
    }

    /**
     * @function handleSearchResultClick
     * @private
     * @description updates the HTML when a search result is clicked
     */
    handleSearchResultClick() {
        removeChildNodes(this.searchResults);
        this.inputField.value = '';
        this.inputField.dispatchEvent(new CustomEvent('input'));
    }

    /**
     * @function populateKvkField
     * @private
     * @param {string} value the kvk number
     */
    populateKvkField(value) {
        this.kvkNumberField.value = value;
        ['input', 'blur'].forEach((event) => this.kvkNumberField.dispatchEvent(new CustomEvent(event)));
    }

    /**
     * @function clearResults
     * @private
     * @description resets the company selector dropdown
     */
    clearResults() {
        this.companySelector.options.length = 1;
        this.companySelector.options[0].removeAttribute('selected');
    }

    /**
     * @function populateCompanyNameField
     * @private
     * @description populates the company name field and triggers change which populates other related fields. See fn OnCompanyChange in checkout.js
     * @param {Object} data the data returned from the details endpoint
     */
    populateCompanyNameField(data) {
        const {
            companyData,
            companyData: { companyName },
            isExistingCompany
        } = data;
        const address = { ...companyData };
        const companies = [{ address, companyName }];

        // When the customer is unkown, enable the chooser information
        showChooserInviteSection(!isExistingCompany);

        this.companySelector.innerHTML = companies.reduce((result, company, index) => {
            if (!company.address?.country) company.address.country = 'NL';
            const stringifiedCompany = JSON.stringify(company).replace(/'/g, '&apos;'); // JSON.stringify does not escape single quotes so it needs to be done manually.

            return `${result}
                <option
                    data-value='${encodeURI(stringifiedCompany)}'
                    value="${company.companyName}"
                    ${index === 0 ? 'selected' : ''}>
                    ${company.companyName}
                </option>
            `;
        }, this.companySelector.innerHTML);

        const postcode = companies[0]?.address?.postalCode;
        const postcodeFormatted = postcode ? postcode.replace(/(^\d{4})([A-Za-z]{2})/, '$1 $2') : '';
        this.querySelector('.js-company-name').innerText = companies[0].companyName;
        this.querySelector('.js-company-address').innerText =
            `${companies[0].address.kvkNumber} - ${companies[0].address.street} ${companies[0].address.houseNumber}${companies[0].address.houseNumberAddition}, ${postcodeFormatted} ${companies[0].address.city}`;
        this.companyInformationCards.forEach((card) => card.setAttribute('visible', 'true'));

        ['change', 'blur'].forEach((event) => this.companySelector.dispatchEvent(new CustomEvent(event)));
        this.companySelector.classList.add('valid');
        const nowTime = moment().tz('Europe/Amsterdam').format('HH:mm');
        const currentTimeInput = document.querySelector('input[name=currentTime]');
        if (currentTimeInput) currentTimeInput.value = nowTime;
    }

    /**
     * @function fetchCompanyDetails
     * @private
     * @description fetches the selected company details on click of a search result
     * @param {string} url the endpoint url
     */
    fetchCompanyDetails(url) {
        this.spinner.start();

        fetch(url)
            .then((res) => res.json())
            .then((res) => {
                this.clearResults();
                this.populateCompanyNameField(res);
                this.form.classList.add('d-none');
                this.result.classList.remove('d-none');

                // This event is used to enable or disable the checkout button in checkout.js when a company is changed. See BO-1648 for more info.
                this.result.dispatchEvent(new CustomEvent('shown:companySearchResult'));
            })
            .finally(this.spinner.stop);
    }

    /**
     * @function fetchCompanyList
     * @private
     * @description fetches the list of companies on entry in the search field
     * @param {string} url the endpoint url
     */
    fetchCompanyList(url) {
        this.spinner.start();

        fetch(url)
            .then((res) => res.json())
            .then((res) => {
                if (!res.success) throw res.serverErrors;
                this.searchResults.innerHTML = res.html || this.constructor.generateResponseHTML(res);
                this.searchResults.classList.add('open');
                this.setAttribute('companies', encodeURI(JSON.stringify(res.companies)));
            })
            .catch((res) => {
                const [title, body] = res;
                removeChildNodes(this.searchResults);
                this.searchResults.innerHTML = `
                    <div class="search-company-results-container bg-white border rounded-bottom position-relative js-company-search-results-container">
                        <ul class="list-group w-100">
                            <li class="search-company-results-item list-group-item rounded-0 font-weight-normal position-relative js-company-search-result-item py-1">
                                <h6 class="search-company-results-item-name m-0 pe-none">${title}</h6>
                                <p class="search-company-results-item-details m-0 text-gray-300 pe-none">${body}</p>
                            </li>
                        </ul>
                    </div>
                `;
            })
            .finally(this.spinner.stop);
    }

    /**
     * @function generateResponseHTML
     *
     * @param {JSON} response - Response from the CompanyInfo-Profile call
     * @return {HTMLElement} search result shown as HTML
     *
     * @description
     * Sync this file with cartridges/app_business_scan/cartridge/templates/default/businessScan/components/searchCompanyResults.isml
     */
    static generateResponseHTML(response) {
        const {
            companyData: { resources, companyName }
        } = response;
        return `
            <div class="search-company-results-container bg-white border rounded-bottom position-relative js-company-search-results-container">
                <h6 class="search-company-results-heading py-1 mb-0">
                    ${resources.header}
                </h6>

                <ul class="list-group w-100">
                    <li class="search-company-results-item list-group-item rounded-0 font-weight-normal position-relative js-company-search-result-item py-1">
                        <h6 class="search-company-results-item-name m-0 pe-none">
                            <strong>${companyName}</strong>
                        </h6>
                        <p class="search-company-results-item-details m-0 text-gray-300 pe-none">
                            <span class="js-search-result-kvk"><strong>${resources.kvk.split(' ').slice(0, 2).join(' ')}</strong></span>
                            <span>${resources.kvk.split(' ').slice(2).join(' ')}</span>
                        </p>
                        <span class="d-none js-business-name">${companyName}</span>
                    </li>
                </ul>
            </div>
        `;
    }

    /**
     * @function onSearchResultClick
     * @private
     * @description handles clicking on a search result by setting calling the details URL for the selected result
     * @param {event} evt a click event on a search result
     */
    onSearchResultClick(evt) {
        const { target } = evt;
        this.searchResultsContainer = this.searchResults.querySelector('.js-company-search-results-container');

        if (target.classList.contains('js-company-search-result-item')) {
            if (this.isLeadForm) {
                // For Lead Form replace search query with selected company name
                const selectedNameField = target.querySelector('.js-business-name');
                if (selectedNameField) {
                    this.inputField.value = selectedNameField.textContent;
                } else {
                    // Clear results on click on 'No results' option
                    removeChildNodes(this.searchResults);
                }
            } else {
                const selectedKvkField = target.querySelector('.js-search-result-kvk');
                if (selectedKvkField) {
                    // Extract the number from the string to populate kvk field
                    const [kvkNumber] = selectedKvkField.textContent.match(/\d+/);

                    // Parse companies to find matching company KVK and create URL
                    let companyDetailsUrl = getCompanyDetailsUrl(JSON.parse(decodeURI(this.getAttribute('companies'))), kvkNumber);
                    companyDetailsUrl = appendParamToURL(companyDetailsUrl, 'investigate', true);
                    const selectedCompanyDetailsUrl = appendCsrfToUrl(companyDetailsUrl);
                    this.handleSearchResultClick();
                    this.populateKvkField(kvkNumber);

                    // Fetch details endpoint and populate company name field
                    this.fetchCompanyDetails(selectedCompanyDetailsUrl);
                }
            }
        }
    }

    /**
     * @function getEndpoint
     * @param {string} value - The value entered by a customer to search for.
     *
     * @return {string} The endpoint to call
     * @description
     * When a customer enters a trade name, the Company-Search controller should be called.
     * When a customer entered a KVK number, the Company-Profile endpoint should be called.
     */
    getEndpoint(value) {
        // Number constructor trims “0” from the beginning of a value.
        // so Number("0123456") becomes 123456 which leads to an incorrect KVK number
        return /^\d{8}$/.test(value) ? `${this.kvkEndpoint}?id=${value}&investigate=true` : `${this.tradenameEndpoint}?q=${value}`;
    }

    /**
     * @function fetchExistingKvk
     * @private
     * @description When a user lands on the page with an existing KVK this function triggers the search to populate companies
     */
    fetchExistingKvk() {
        const existingKvkNumber = this.kvkNumberField.value;
        const url = appendCsrfToUrl(this.getEndpoint(existingKvkNumber));
        this.fetchCompanyList(url);
    }

    /**
     * @function onExistingKvkFetch
     * @private
     * @description Called when companies attribute changes, this function fetches company details for an existing KVK number
     * @param {string} companies an encoded string of an array of companies - coming from attributeChangedCallback
     */
    onExistingKvkFetch(companies) {
        const existingKvkNumber = this.kvkNumberField.value;
        if (!companies || !existingKvkNumber) return;
        let companyDetailsUrl = getCompanyDetailsUrl(JSON.parse(decodeURI(companies)), existingKvkNumber);
        companyDetailsUrl = appendParamToURL(companyDetailsUrl, 'investigate', true);
        const selectedCompanyDetailsUrl = appendCsrfToUrl(companyDetailsUrl);
        this.fetchCompanyDetails(selectedCompanyDetailsUrl);
    }

    /**
     * @function onKeyup
     * @private
     * @description handles entry in the search field. Calls company search endpoint and clears related fields.
     * @param {event} evt a keyup event on the search field
     */
    onKeyup(evt) {
        const {
            target: { value: searchQuery }
        } = evt;
        const minSearchQueryLength = startsWithNumber(searchQuery) ? this.minQueryLengthNumber : this.minQueryLengthText;
        const meetsMinLengthRequirement = searchQuery.length >= minSearchQueryLength;
        clearCompanyRelatedFields();

        if (meetsMinLengthRequirement) {
            const url = appendCsrfToUrl(this.getEndpoint(removeAccentCharacters(searchQuery)));
            this.fetchCompanyList(url);
        } else if (this.isLeadForm) {
            // Clear results if the query removed
            removeChildNodes(this.searchResults);
        }
    }

    /**
     * @function onKeydown
     * @description Prevents the checkout form being submitted if enter is pressed from within the KVK field.
     * @param {Event} event - The keydown event on the company search field.
     */
    static onKeydown(event) {
        if (event.key === 'Enter' || event.keyCode === 13) {
            event.preventDefault();
        }
    }

    onEdit() {
        this.form.classList.remove('d-none');
        this.result.classList.add('d-none');
        this.inputField.value = '';
        this.clearResults();
        showChooserInviteSection(false);
    }
}

if (!window.customElements.get('vz-company-search')) {
    window.customElements.define('vz-company-search', VZCompanySearch);
}
