'use strict';

import { setCookie, getCookie } from 'Util/cookies';

/**
 * @private startDevToolsDetector
 *
 * @description
 * Detect whether chrome devtools is open or not.
 * Based on https://stackoverflow.com/a/67148898/8823973
 *
 * Once detected, set a cookie and never fire this again. The cookie is used in the following file
 * to determine that the "iPad useragent" is in fact an iPad emulation in Chrome/Firefox devtools:
 * app_vodafone_ziggo/cartridge/scripts/helpers/hookHelpers.js
 *
 * It has been a real challenge to find a method that works properly with the current version of
 * Chrome (v95). This method seems to work since version 89.
 * Chrome usually patches "hacks" that try to detect this, so this might stop working in the future.
 * Good luck to the chap that can search for a new way to detect devtools in that case.
 */
function startDevToolsDetector() {
    const devToolsOpen = () => {};

    const interval = setInterval(() => {
        console.profile(devToolsOpen);
        console.profileEnd(devToolsOpen);
    }, 5000);

    devToolsOpen.toString = () => {
        setCookie('devToolsUser', true, window.location.hostname, 365);
        clearInterval(interval);
        return '-';
    };
}

if (!getCookie('devToolsUser')) {
    startDevToolsDetector();
}
